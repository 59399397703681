import axios from "../http-file";

import {
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_SUCCESS,
} from "../constants/authConstants";

export const signIn = (userData) => async (dispatch) => {
  dispatch({
    type: USER_SIGNIN_FAIL,
    payload: "",
  });
  dispatch({ type: USER_SIGNIN_REQUEST, payload: userData });
  try {
    const { data } = await axios.post("/auth/login", userData);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
export const signUp = (userData) => async (dispatch) => {
  dispatch({
    type: USER_SIGNUP_FAIL,
    payload: "",
  });
  dispatch({
    type: USER_SIGNUP_REQUEST,
    payload: userData,
  });
  try {
    const { data } = await axios.post("/auth/register", userData);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNUP_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_SIGNUP_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const signout = (userData) => async (dispatch) => {
  await axios.post("/auth/logout", userData);
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_SIGNIN_SUCCESS, payload: null });

  dispatch({ type: USER_SIGNOUT });
};
