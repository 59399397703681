import { useEffect } from "react";
import styled from "styled-components";
import { BiPhone } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

import { Zoom } from "react-awesome-reveal";
import { socials } from "../data/socials";
import { AiOutlineMail } from "react-icons/ai";

const Contact = () => {
  useEffect(() => {
    document.title =
      "Contact Us - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);

  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Contact Us" />
      </head>
      <Content>
        <Cards className="wrapper">
          <Card>
            <Header>
              <Zoom triggerOnce={true}>Contact Us</Zoom>
            </Header>
            <HorizontalWrapper>
              <Zoom triggerOnce={true}>
                <Title1>
                  If you need any more information, feel free to
                  <span> contact us!</span>
                </Title1>
              </Zoom>
            </HorizontalWrapper>
            <HorizontalWrapper>
              <a href="tel:+254791480876">
                <IconWrapper>
                  <BiPhone color="#fff" size="23" />
                </IconWrapper>
              </a>
              <Zoom triggerOnce={true}>
                <VerticalWrapper>
                  <Title2>Call our director:</Title2>
                  <Title3>
                    <a href="tel:+254722694229">(+254) 722 694 229</a>
                  </Title3>
                </VerticalWrapper>
              </Zoom>
            </HorizontalWrapper>
            <HorizontalWrapper>
              <a
                href={`https://wa.me/+254722694229?text=`}
                target="_blank"
                rel="noreferrer"
              >
                <IconWrapper>
                  <FaWhatsapp color="#fff" size="23" />
                </IconWrapper>
              </a>
              <Zoom triggerOnce={true}>
                <VerticalWrapper>
                  <Title2>Reach us via Whatsapp:</Title2>
                  <h6>(Click below to text)</h6>
                  <Title3>
                    <a
                      href={`https://wa.me/+254722694229?text=}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      (+254) 722 694 229
                    </a>
                  </Title3>
                </VerticalWrapper>
              </Zoom>
            </HorizontalWrapper>
            <HorizontalWrapper>
              <a href="mailto:info@ardap.org" target="_blank" rel="noreferrer">
                <IconWrapper>
                  <AiOutlineMail color="#fff" size="23" />
                </IconWrapper>
              </a>
              <Zoom triggerOnce={true}>
                <VerticalWrapper>
                  <Title2>Reach us via email:</Title2>
                  <h6>(Click below to text)</h6>
                  <Title3>
                    <a
                      href="mailto:info@ardap.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@ardap.org
                    </a>
                  </Title3>
                </VerticalWrapper>
              </Zoom>
            </HorizontalWrapper>
            <Socials>
              <Zoom cascade damping={0.3} triggerOnce={true}>
                {socials.map((social, i) => (
                  <SocialIconWrapper
                    href={social.url}
                    target="_blank"
                    key={i}
                    prop={social.prop}
                  >
                    <Zoom triggerOnce={true}>{social.icon}</Zoom>
                  </SocialIconWrapper>
                ))}
              </Zoom>
            </Socials>
          </Card>
        </Cards>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
  min-height: calc(100vh - 150px);
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title1 = styled.h1`
  color: #000;
  font-weight: 900;
  font-size: 39px;
  margin-top: -10px;
  @media screen and (max-width: 800px) {
    font-size: 28px;
  }
  span {
    color: brown;
  }
`;

const Card = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #d9d9d9;
  background-color: #fff;
  width: 100%;
  max-width: 700px;
  @media screen and (max-width: 800px) {
    width: 100%;
    &:first-child {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  &:first-child {
    margin-right: 15px;
  }
  button {
    padding: 20px 0;
    background-color: brown;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 7px;
    cursor: pointer;
    width: 100%;
    transition: all 0.7s linear;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 800px) {
      font-size: 15px;
      padding: 12px 0;
    }
    &:hover {
      background-color: #ff0000;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const Cards = styled.div`
  display: flex;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
`;
const IconWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  background-color: brown;
  border-radius: 50%;
  margin-right: 35px;
  @media screen and (max-width: 800px) {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }
`;

const Title2 = styled.h1`
  color: #000;
  font-weight: 700;
  font-size: 25px;
  margin-top: -10px;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
  span {
    color: brown;
  }
`;
const Title3 = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 19px;
  margin-top: 10px;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 17px;
  }
  span {
    color: brown;
  }
`;
const HorizontalWrapper = styled.div`
  display: flex;

  width: 100%;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  h6 {
    margin-top: 3px;
  }
`;

const Header = styled.h3`
  color: brown;
  font-size: 18px;
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 35px;
    height: 5px;
    background: brown;
    pointer-events: none;

    z-index: 1;
  }
`;
const Socials = styled.div`
  display: flex;
  padding: 20px 0 20px 50px;
`;
const SocialIconWrapper = styled.a`
  display: grid;
  place-items: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
  color: #fff;
  background-color: ${({ prop }) =>
    prop === "one"
      ? "#1884BC"
      : prop === "two"
      ? "#000"
      : prop === "three"
      ? "#c82755"
      : prop === "four"
      ? "#4E69A2"
      : "#33AAF3"};
  border-radius: 50%;
  margin-right: 10px;
  @media screen and (max-width: 800px) {
    width: 40px;
    height: 40px;
  }
`;
export default Contact;
