export const ADD_TO_REPORTS_REQUEST = "ADD_TO_REPORTS_REQUEST";
export const ADD_TO_REPORTS_SUCCESS = "ADD_TO_REPORTS_SUCCESS";
export const ADD_TO_REPORTS_FAIL = "ADD_TO_REPORTS_FAIL";

export const GET_REPORTS_REQUEST = "GET_REPORTS_REQUEST";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL";

export const DELETE_REPORT_REQUEST = "DELETE_REPORT_REQUEST";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAIL = "DELETE_REPORT_FAIL";

export const UPDATE_REPORT_REQUEST = "UPDATE_REPORT_REQUEST";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAIL = "UPDATE_REPORT_FAIL";
