import {
  ADD_TO_GALLERY_FAIL,
  ADD_TO_GALLERY_REQUEST,
  ADD_TO_GALLERY_SUCCESS,
  DELETE_GALLERY_IMAGES_FAIL,
  DELETE_GALLERY_IMAGES_REQUEST,
  DELETE_GALLERY_IMAGES_SUCCESS,
  GET_GALLERY_FAIL,
  GET_GALLERY_REQUEST,
  GET_GALLERY_SUCCESS,
  UPDATE_GALLERY_IMAGES_FAIL,
  UPDATE_GALLERY_IMAGES_REQUEST,
  UPDATE_GALLERY_IMAGES_SUCCESS,
} from "../constants/galleryConstants";
import axios from "../http-file";
export const addToGallery = (images) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TO_GALLERY_FAIL,
    payload: "",
  });
  dispatch({
    type: ADD_TO_GALLERY_SUCCESS,
    payload: null,
  });
  dispatch({ type: ADD_TO_GALLERY_REQUEST });
  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.post(
      "/gallery",

      images,

      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: ADD_TO_GALLERY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADD_TO_GALLERY_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const getGallery = () => async (dispatch, getState) => {
  dispatch({
    type: GET_GALLERY_FAIL,
    payload: "",
  });
  dispatch({ type: GET_GALLERY_REQUEST });
  try {
    const { data } = await axios.get("/gallery");
    dispatch({ type: GET_GALLERY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_GALLERY_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const deleteGalleryImage = (itemId) => async (dispatch, getState) => {
  dispatch({ type: DELETE_GALLERY_IMAGES_FAIL, payload: null });
  dispatch({ type: DELETE_GALLERY_IMAGES_SUCCESS, payload: null });
  dispatch({ type: DELETE_GALLERY_IMAGES_REQUEST });

  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.delete(`/gallery/${itemId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: DELETE_GALLERY_IMAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_GALLERY_IMAGES_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
export const updateGalleryImage =
  (itemId, update) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_GALLERY_IMAGES_FAIL, payload: null });
    dispatch({ type: UPDATE_GALLERY_IMAGES_SUCCESS, payload: null });
    dispatch({ type: UPDATE_GALLERY_IMAGES_REQUEST });

    try {
      const token = getState()?.signIn?.userInfo?.token;
      const { data } = await axios.put(`/gallery/${itemId}`, update, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: UPDATE_GALLERY_IMAGES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: UPDATE_GALLERY_IMAGES_FAIL,
        payload:
          error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message,
      });
    }
  };
