import {
  ADD_TO_PLANS_FAIL,
  ADD_TO_PLANS_REQUEST,
  ADD_TO_PLANS_SUCCESS,
  DELETE_PLAN_FAIL,
  DELETE_PLAN_REQUEST,
  DELETE_PLAN_SUCCESS,
  GET_PLANS_FAIL,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  UPDATE_PLAN_FAIL,
  UPDATE_PLAN_REQUEST,
  UPDATE_PLAN_SUCCESS,
} from "../constants/planConstants";
import axios from "../http-file";
export const addToPlans = (plan) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TO_PLANS_FAIL,
    payload: "",
  });
  dispatch({
    type: ADD_TO_PLANS_SUCCESS,
    payload: "",
  });
  dispatch({ type: ADD_TO_PLANS_REQUEST });
  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.post("/strategic-plans", plan, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: ADD_TO_PLANS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADD_TO_PLANS_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const getPlans = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PLANS_FAIL,
    payload: "",
  });
  dispatch({ type: GET_PLANS_REQUEST });
  try {
    const { data } = await axios.get("/strategic-plans");
    dispatch({ type: GET_PLANS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_PLANS_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const deletePlan = (itemId) => async (dispatch, getState) => {
  dispatch({ type: DELETE_PLAN_FAIL, payload: null });
  dispatch({ type: DELETE_PLAN_SUCCESS, payload: null });
  dispatch({ type: DELETE_PLAN_REQUEST });

  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.delete(`/strategic-plans/${itemId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: DELETE_PLAN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_PLAN_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
export const updatePlan = (itemId, update) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_PLAN_FAIL, payload: null });
  dispatch({ type: UPDATE_PLAN_SUCCESS, payload: null });
  dispatch({ type: UPDATE_PLAN_REQUEST });

  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.put(`/strategic-plans/${itemId}`, update, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: UPDATE_PLAN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_PLAN_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
