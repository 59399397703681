import {
  ADD_TO_HOME_IMAGES_FAIL,
  ADD_TO_HOME_IMAGES_REQUEST,
  ADD_TO_HOME_IMAGES_SUCCESS,
  DELETE_HOME_IMAGES_FAIL,
  DELETE_HOME_IMAGES_REQUEST,
  DELETE_HOME_IMAGES_SUCCESS,
  GET_HOME_IMAGES_FAIL,
  GET_HOME_IMAGES_REQUEST,
  GET_HOME_IMAGES_SUCCESS,
  UPDATE_HOME_IMAGES_FAIL,
  UPDATE_HOME_IMAGES_REQUEST,
  UPDATE_HOME_IMAGES_SUCCESS,
} from "../constants/homeImagesConstants";

export const addToHomeImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_HOME_IMAGES_REQUEST:
      return { ...state, loading: true };
    case ADD_TO_HOME_IMAGES_SUCCESS:
      return { ...state, loading: false, images: action.payload };
    case ADD_TO_HOME_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getHomeImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_IMAGES_REQUEST:
      return { ...state, loading: true };
    case GET_HOME_IMAGES_SUCCESS:
      return { ...state, loading: false, images: action.payload };
    case GET_HOME_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const deleteHomeImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_HOME_IMAGES_REQUEST:
      return { ...state, loading: true };
    case DELETE_HOME_IMAGES_SUCCESS:
      return { ...state, loading: false, image: action.payload };
    case DELETE_HOME_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateHomeImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_HOME_IMAGES_REQUEST:
      return { ...state, loading: true };
    case UPDATE_HOME_IMAGES_SUCCESS:
      return { ...state, loading: false, updated: action.payload };
    case UPDATE_HOME_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
