import React, { useEffect } from "react";

import styled from "styled-components";
import GalleryItem from "../components/GalleryItem";

import { useDispatch, useSelector } from "react-redux";
import { getGallery } from "../redux/actions/galleryActions";
import Loader from "../components/Loader";
import { useIsMount } from "../hooks/useIsMount";
import { toast } from "react-toastify";

const Gallery = () => {
  useEffect(() => {
    document.title =
      "Gallery - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const { images, loading, error } = useSelector((state) => state.getGallery);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGallery());
    // eslint-disable-next-line
  }, []);
  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (error) {
        errorExists(error);
      }
    }
    // eslint-disable-next-line
  }, [error]);
  return (
    <Wrapper>
      <TopBanner>
        <div className="left">
          <h1>Gallery</h1>
        </div>
        <div className="right">
          <img
            src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
            alt="Farm"
          />
        </div>
      </TopBanner>

      <Content className="wrapper">
        {loading ? (
          <Loader />
        ) : images?.length === 0 ? (
          <h1 style={{ marginTop: "40px" }}>There are no images to show!</h1>
        ) : (
          <ItemsWrapper>
            {images?.map((image) => (
              <GalleryItem key={image._id} {...image} />
            ))}
          </ItemsWrapper>
        )}
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 150px);
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;
  & .left {
    background: #004600;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 60px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;
const Content = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: minmax(275px, 1fr); */
  margin-bottom: 40px;
`;

export default Gallery;
