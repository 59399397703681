import React from "react";
import styled from "styled-components";

const Modal = ({ children }) => {
  return <MyModal>{children}</MyModal>;
};
const MyModal = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  display: grid;
  place-items: center;
  z-index: 100;
`;

export default Modal;
