import {
  ADD_TO_PLANS_FAIL,
  ADD_TO_PLANS_REQUEST,
  ADD_TO_PLANS_SUCCESS,
  DELETE_PLAN_FAIL,
  DELETE_PLAN_REQUEST,
  DELETE_PLAN_SUCCESS,
  GET_PLANS_FAIL,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  UPDATE_PLAN_FAIL,
  UPDATE_PLAN_REQUEST,
  UPDATE_PLAN_SUCCESS,
} from "../constants/planConstants";

export const addToPlansReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_PLANS_REQUEST:
      return { ...state, loading: true };
    case ADD_TO_PLANS_SUCCESS:
      return { ...state, loading: false, added: action.payload };
    case ADD_TO_PLANS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getPlansReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PLANS_REQUEST:
      return { ...state, loading: true };
    case GET_PLANS_SUCCESS:
      return { ...state, loading: false, plans: action.payload };
    case GET_PLANS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const deletePlanReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PLAN_REQUEST:
      return { ...state, loading: true };
    case DELETE_PLAN_SUCCESS:
      return { ...state, loading: false, deleted: action.payload };
    case DELETE_PLAN_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updatePlanReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PLAN_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PLAN_SUCCESS:
      return { ...state, loading: false, updated: action.payload };
    case UPDATE_PLAN_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
