import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export const socials = [
  {
    icon: <FaFacebookF size={20} />,
    url: "https://web.facebook.com/profile.php?id=61555729591574",
    prop: "four",
  },
  {
    icon: <FaInstagram size={20} />,
    url: "https://www.instagram.com/ardap_org/",
    prop: "three",
  },
  {
    icon: <FaXTwitter size={20} />,
    url: "https://twitter.com/ardap_org",
    prop: "two",
  },
];
