import {
  ADD_TO_HOME_IMAGES_FAIL,
  ADD_TO_HOME_IMAGES_REQUEST,
  ADD_TO_HOME_IMAGES_SUCCESS,
  DELETE_HOME_IMAGES_FAIL,
  DELETE_HOME_IMAGES_REQUEST,
  DELETE_HOME_IMAGES_SUCCESS,
  GET_HOME_IMAGES_FAIL,
  GET_HOME_IMAGES_REQUEST,
  GET_HOME_IMAGES_SUCCESS,
  UPDATE_HOME_IMAGES_FAIL,
  UPDATE_HOME_IMAGES_REQUEST,
  UPDATE_HOME_IMAGES_SUCCESS,
} from "../constants/homeImagesConstants";
import axios from "../http-file";
export const addToHomeImages = (image) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TO_HOME_IMAGES_FAIL,
    payload: "",
  });
  dispatch({
    type: ADD_TO_HOME_IMAGES_SUCCESS,
    payload: "",
  });
  dispatch({ type: ADD_TO_HOME_IMAGES_REQUEST });
  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.post("/home-images", image, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: ADD_TO_HOME_IMAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADD_TO_HOME_IMAGES_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const getHomeImages = () => async (dispatch, getState) => {
  dispatch({
    type: GET_HOME_IMAGES_FAIL,
    payload: "",
  });
  dispatch({ type: GET_HOME_IMAGES_REQUEST });
  try {
    const { data } = await axios.get("/home-images");
    dispatch({ type: GET_HOME_IMAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_HOME_IMAGES_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const deleteHomeImage = (itemId) => async (dispatch, getState) => {
  dispatch({ type: DELETE_HOME_IMAGES_FAIL, payload: null });
  dispatch({ type: DELETE_HOME_IMAGES_SUCCESS, payload: null });
  dispatch({ type: DELETE_HOME_IMAGES_REQUEST });

  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.delete(`/home-images/${itemId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: DELETE_HOME_IMAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_HOME_IMAGES_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
export const updateHomeImage = (itemId,update) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_HOME_IMAGES_FAIL, payload: null });
  dispatch({ type: UPDATE_HOME_IMAGES_SUCCESS, payload: null });
  dispatch({ type: UPDATE_HOME_IMAGES_REQUEST });

  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.put(`/home-images/${itemId}`,update, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: UPDATE_HOME_IMAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_HOME_IMAGES_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};