import React from "react";
import styled from "styled-components";
const SignupButton = ({ title, disabled, onClick, style }) => {
  return (
    <Button disabled={disabled} onClick={onClick} style={style}>
      {title}
    </Button>
  );
};
const Button = styled.button`
  background: #004600;
  border-radius: 8px;
  border: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  padding: 10px 50px;
  cursor: pointer;
  transition: all 0.6s linear;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    background: gray;
    cursor: not-allowed;
  }
`;
export default SignupButton;
