import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SingleNews = ({
  image,
  title,
  description,
  setShowDelete,
  setItemId,
  author,
  createdAt,
  _id,
}) => {
  const handleDelete = () => {
    setItemId(_id);
    setShowDelete(true);
  };
  return (
    <ItemWrapper>
      <Image src={image?.url} />
      <Caption>{title.substring(0, 100)}</Caption>
      <Author>{`By: ${author} On: ${new Date(createdAt).getDate()}-${
        new Date(createdAt).getMonth() + 1
      }-${new Date(createdAt).getFullYear()}`}</Author>
      <Description>{description.substring(0, 200)}</Description>
      <Buttons>
        <Link to={`/admin/admin-news-and-events/${_id}`}>
          <DetailsButton>More details</DetailsButton>
        </Link>
        <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
      </Buttons>
    </ItemWrapper>
  );
};
const ItemWrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: 270px;
  max-width: 470px;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid brown;

  border-radius: 10px;
  padding-bottom: 14px;
  &:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  transition: all 0.7s linear;
  &:hover {
    opacity: 0.8;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const Caption = styled.h1`
  color: #000;
  font-size: 17px;
  padding: 0 14px;
  position: relative;
`;
const Description = styled.h1`
  color: #000;
  font-size: 15px;
  padding: 0 14px;
  font-style: italic;
  margin: 10px 0;
`;
const Author = styled.h1`
  color: #000;
  font-size: 15px;
  padding: 7px 14px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  margin-top: 5px;
`;
const DeleteButton = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  font-family: "Dm Sans";
  background: red;
  border-radius: 10px;
  padding: 7px 20px;
  color: #ffffff;
  cursor: pointer;
`;
const DetailsButton = styled(DeleteButton)`
  background: #004600;
`;
export default SingleNews;
