import { useEffect } from "react";
import styled from "styled-components";

import { Zoom } from "react-awesome-reveal";

const Staff = () => {
  useEffect(() => {
    document.title =
      "Our Staff - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const baseDir = process.env.PUBLIC_URL;
  const director = {
    name: "Wesonga Macdonald",
    title: "Executive Director",
    image: `${baseDir}/md.jpg`,
    desc: "Wesonga Macdonald holds a B.Sc. in Agriculture from Kenya Methodist University - Meru and a Certificate in Bio-Intensive Agriculture from Manor House Agricultural Centre. He is a founder of Appropriate Rural Development Agriculture Program (ARDAP).",
    moreInfo:
      "Since 1995 he has gained vast practical experience in rural development both at field level and effective managerial assignments in various organizations, expertise in farmer led experimentations and technology adoption in sustainable agriculture, seed systems, community based organizational management, soil fertility management participatory project development, management and evaluation and farmer trainer.Before founding ARDAP in 2000 Mr Wesonga worked at Rural Community Development Agency in Meru as Development Coordinator - Food Security Program and as a Volunteer Agriculture Program Trainer at the Anglican Church of Kenya Diocese of Nambale.",
  };
  const programsLiaison = {
    name: "Alice Nakhumicha Masinde",
    title: "Programs Field Liaison Officer",
    image: `${baseDir}/alice.jpeg`,
    desc: "Alice Nakhumicha Masinde holds an Advanced Diploma in Community Based Development and Project Management. She is currently working as the Programs Field Liaison Officer at ARDAP Kenya Organization coordinating Sustainable Agriculture and Gender & Women Integration Programmes. ",
    moreInfo:
      " Over the last fifteen (15) years, she has worked with small holder farmer groups at grassroots level in Busia District to build their requisite capacities in relevant sustainable agriculture technologies and food security.She is also an able master trainer in participatory approaches and community base designed programmes aimed at improving nutrition status and economic stability for community members. She has coordinated and implemented small holder farmer group programmes on post-harvest handling, utilization and value addition of indigenous vegetables, sweet potatoes, grain legumes, roots and tubers, environmental conservation and soil fertility management in Busia, Siaya, Teso, and Bungoma District. Alice also has diverse experiences in handling issues relating to social behavioral change, community surveys, project planning, budgeting, gender integration, project resource mobilization and community transformation. Overall, she deputizes the Organization’s Director in providing leadership, coordination and management to organization staff and community resource persons. ",
  };
  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Staff" />
      </head>
      <Content>
        <Cards className="wrapper">
          <Header>
            <Zoom triggerOnce={true}>Staff Profile</Zoom>
          </Header>
          <Card>
            <VerticalWrapper>
              <HorizontalWrapper>
                <VerticalWrapper>
                  <Image src={director.image} />
                  <Title1>{director.name}</Title1>
                  <Title2>{director.title}</Title2>
                </VerticalWrapper>
                <Title3>{director.desc}</Title3>
              </HorizontalWrapper>

              <Title4>{director.moreInfo}</Title4>
            </VerticalWrapper>
          </Card>
          <Card>
            <VerticalWrapper>
              <HorizontalWrapper>
                <VerticalWrapper>
                  <Image src={programsLiaison.image} />
                  <Title1>{programsLiaison.name}</Title1>
                  <Title2>{programsLiaison.title}</Title2>
                </VerticalWrapper>
                <Title3>{programsLiaison.desc}</Title3>
              </HorizontalWrapper>

              <Title4>{programsLiaison.moreInfo}</Title4>
            </VerticalWrapper>
          </Card>
        </Cards>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
  min-height: calc(100vh - 150px);
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
`;

const Title1 = styled.h1`
  color: brown;
  font-weight: 900;
  font-size: 21px;
  margin-top: 20px;
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    font-size: 28px;
  }
  span {
    color: brown;
  }
`;

const Card = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #d9d9d9;
  background-color: #fff;
  width: 100%;
  max-width: 700px;
  margin-bottom: 30px;
`;

const Cards = styled.div`
  display: flex;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title2 = styled.h1`
  color: #000;
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    font-size: 17px;
    margin-bottom: 20px;
  }
`;
const Title3 = styled.h1`
  color: #000;
  font-weight: 700;
  font-size: 17px;
  margin-left: 10px;
  @media screen and (max-width: 800px) {
    font-size: 15px;
    margin-left: 0px;
  }
`;
const Title4 = styled.h1`
  color: #000;
  font-weight: 700;
  font-size: 16px;
  font-style: italic;

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const HorizontalWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  &:first-child {
    margin-right: 10px;
  }
`;

const Header = styled.h3`
  color: brown;
  font-size: 18px;
  position: relative;
  padding-left: 40px;
  margin-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 35px;
    height: 5px;
    background: brown;
    pointer-events: none;

    z-index: 1;
  }
`;

export default Staff;
