import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import InputWithLabel from "../../components/InputWithLabel";

import SignupButton from "../../components/SignupButton";

import TextAreaWithLabel from "../../components/admin/TextAreaWithLabel";

import { useIsMount } from "../../hooks/useIsMount";
import { HiPhotograph } from "react-icons/hi";

import { createNewsAndEvents } from "../../redux/actions/newsActions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const AddNewsAndEvents = () => {
  const { added, loading, error } = useSelector(
    (state) => state.createNewsAndEvents
  );
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const [category, setCategory] = useState("News");
  const [image, setEventImage] = useState();
  const [event_date, setEventDate] = useState();
  const [description, setDescription] = useState();

  const handleImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setEventImage(reader.result);
    };
  };
  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const success = (message) => {
    return toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (error) {
        errorExists(error);
      }
    }
    // eslint-disable-next-line
  }, [error]);
  useEffect(() => {
    if (!isMount) {
      if (added) {
        success("Event/news saved successfully!");
        setEventImage(null);
        setTitle("");
        setDescription("");
      }
    }
    // eslint-disable-next-line
  }, [added]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!description || !title || !image) alert("Kindly fill all the fields");
    else {
      const event =
        category === "Events"
          ? {
              image,
              event_date,
              title,
              category,
              description,
            }
          : {
              image,
              title,
              category,
              description,
            };
      dispatch(createNewsAndEvents(event));
    }
  };

  const handleOptionChange = (value) => {
    setCategory(value);
  };
  return (
    <Wrapper>
      <TopBanner>
        <div className="left">
          <h1>Add News or event</h1>
        </div>
        <div className="right">
          <img
            src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
            alt="Farm"
          />
        </div>
      </TopBanner>
      <Link to="/admin/admin-news-and-events">
        <Button back>Back</Button>
      </Link>
      <Form>
        <RadioContainer>
          <RadioButton>
            <input
              type="radio"
              value="News"
              name="category"
              checked={category === "News"}
              onChange={() => handleOptionChange("News")}
            />
            News
          </RadioButton>

          <RadioButton>
            <input
              type="radio"
              value="Events"
              name="category"
              checked={category === "Events"}
              onChange={() => handleOptionChange("Events")}
            />
            Events
          </RadioButton>
        </RadioContainer>

        <InputWithLabel
          label="Event/News title"
          placeholder="Enter Event/News title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextAreaWithLabel
          label="Event/News description"
          placeholder="Enter more information"
          value={description}
          rows="7"
          onChange={(e) => setDescription(e.target.value)}
        />
        {category === "Events" && (
          <InputWithLabel
            label="Event date"
            type="date"
            placeholder="Enter Event date"
            value={event_date}
            onChange={(e) => setEventDate(e.target.value)}
          />
        )}
        <Title2>Select an image</Title2>

        <Label htmlFor="photo">
          <FileInput
            type="file"
            id="photo"
            accept=".png,.jpg,.jpeg"
            onChange={(e) => handleImage(e)}
          />
          <Icon id="photo">
            <HiPhotograph />
          </Icon>
        </Label>
        {image && <Image src={image} />}
        <SignupButton
          title={loading ? "Creating ...." : "Create"}
          disabled={loading}
          onClick={(e) => handleSubmit(e)}
        />
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  padding: 30px 0;
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;
  & .left {
    background: #004600;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 60px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;

const Button = styled.button`
  background: #004600;
  border-radius: 8px;
  border: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  /* width: 400px; */
  padding: 10px 40px;
  margin-top: 30px;
  margin-bottom: ${(props) => props.back && "20px"};
  cursor: pointer;
  transition: all 0.6s linear;
  &:disabled {
    cursor: not-allowed;
    background: gray;
  }
  &:hover {
    opacity: 0.8;
  }
`;
const RadioContainer = styled.div`
  display: flex;

  margin: 15px 0;
`;

const RadioButton = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  input {
    margin-right: 5px;
  }
`;
const Image = styled.img`
  width: 90%;
  height: 220px;
  object-fit: cover;
  margin-bottom: 10px;
`;
const Form = styled.form`
  display: flex;
  width: 456px;
  flex-direction: column;
  align-items: center;
`;
const Label = styled.label`
  cursor: pointer;
  display: flex;
  position: relative;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  color: #000f2d;
  margin-left: 10px;
`;
const Title2 = styled.h1`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 28px;
  color: #141414;
`;
const FileInput = styled.input`
  display: none;
  background: #ffffff;
  border: 1.2px solid #f1f1f1;
  border-radius: 8px;
  align-self: flex-start;
  padding: 12px;

  width: 100%;

  margin-bottom: 20px;
  &:invalid {
    border-color: red;
  }
`;
export default AddNewsAndEvents;
