import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { BsExclamationCircleFill } from "react-icons/bs";

import { toast } from "react-toastify";
import { useIsMount } from "../../hooks/useIsMount";

import { deletePlan, getPlans } from "../../redux/actions/planActions";

const ConfirmPlanDelete = ({ setShowDelete, itemId }) => {
  const { deleted, loading, error } = useSelector((state) => state.deletePlan);

  const dispatch = useDispatch();

  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const success = (message) => {
    return toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (error) {
        errorExists(error);
      }
    }
  }, [error, isMount]);

  useEffect(() => {
    if (!isMount) {
      if (deleted) {
        success("Plan deleted successfully");
        dispatch(getPlans());
        setShowDelete(false);
      }
    }
    // eslint-disable-next-line
  }, [deleted, isMount]);
  const handleClick = () => {
    dispatch(deletePlan(itemId));
  };
  return (
    <ItemWrapper>
      <Icon>
        <BsExclamationCircleFill color="red" />
      </Icon>

      <Title2>
        Are you sure you want to delete this item? Once deleted, it can't be
        undone.
      </Title2>
      <Button onClick={handleClick} disabled={loading}>
        {loading ? "Hold On..." : "Delete item"}
      </Button>

      <Button cancel onClick={() => setShowDelete(false)}>
        Cancel
      </Button>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  padding: 45px;
  width: 90vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  align-items: center;
`;

const Button = styled.button`
  padding: 10px 32px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  height: fit-content;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  background-color: ${({ cancel }) => (cancel ? "trasparent" : "red")};
  color: ${({ cancel }) => (cancel ? "#000" : "#fff")};
  margin: 10px 0;
`;

const Title2 = styled.h1`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #0a0e27;
`;

const Icon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: lavender;
  margin-bottom: 20px;
  display: grid;
  place-items: center;
`;
export default ConfirmPlanDelete;
