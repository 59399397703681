import React, { useEffect } from "react";

import styled from "styled-components";
import { changeActiveDonor } from "../redux/actions/activeTabActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Donors = () => {
  const dispatch = useDispatch();
  const handleDonorClick = (index) => {
    dispatch(changeActiveDonor(index));
  };
  useEffect(() => {
    document.title =
      "Donors - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const activeIndex = useSelector((state) => state.activeTab);
  const donors = [
    {
      id: 0,
      title: "Core Donors",
      url: "/core-donors",
      donors: [
        {
          id: 0,
          img: `${process.env.PUBLIC_URL}/tudor_logo.png`,
          name: "Tudor Trust",
        },
        {
          id: 1,
          img: `${process.env.PUBLIC_URL}/sha_logo.jpg`,
          name: "Self Help Africa",
        },
      ],
    },
    {
      id: 1,
      title: "Project Donors",
      url: "/project-donors",
      donors: [
        {
          id: 0,
          img: `${process.env.PUBLIC_URL}/agra_logo.png`,
          name: "Alliance for Green Revolution Africa",
        },
        {
          id: 1,
          img: `${process.env.PUBLIC_URL}/usaid_logo.png`,
          name: "USAID",
        },
      ],
    },
    {
      id: 2,
      title: "Research Partners",
      url: "/research-partners",
      donors: [
        {
          id: 0,
          img: `${process.env.PUBLIC_URL}/manor_house_logo.png`,
          name: "Manor House Agric Centre",
        },
        {
          id: 1,
          img: `${process.env.PUBLIC_URL}/moi_uni_logo.jpg`,
          name: "Moi University",
        },
        {
          id: 2,
          img: `${process.env.PUBLIC_URL}/fan_logo.jpg`,
          name: "Forest Action Network",
        },
        {
          id: 3,
          img: `${process.env.PUBLIC_URL}/kari_logo.jpg`,
          name: "Kenya Agricultural Research Institute",
        },
        {
          id: 4,
          img: `${process.env.PUBLIC_URL}/moa_logo.jpg`,
          name: "Ministry of Agriculture, Kenya",
        },
      ],
    },

    {
      id: 3,
      title: "Communities",
      url: "/communities",
      donors: [
        {
          id: 0,
          img: `${process.env.PUBLIC_URL}/ucrc_logo.jpg`,
          name: "Ugunja Community Resource Center",
        },
        {
          id: 1,
          img: `${process.env.PUBLIC_URL}/crep_logo.jpg`,
          name: "Community Rehabilitation and Environmental Protection",
        },
      ],
    },
  ];

  return (
    <Wrapper>
      <TopBanner>
        <div className="left">
          <h1>{donors.at(activeIndex.donor)?.title}</h1>
        </div>
        <div className="right">
          <img
            src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
            alt="Farm"
          />
        </div>
      </TopBanner>
      <Title1 className="wrapper">
        {`Home > Donors & Partners > ${donors.at(activeIndex.donor)?.title}`}
      </Title1>
      <Content className="wrapper">
        <Left>
          {donors.map((donor, index) => (
            <Link to={`/donors-and-partners${donor.url}`}>
              <Title2
                key={donor.id}
                active={index === activeIndex.donor}
                onClick={() => handleDonorClick(index)}
              >
                {donor.title}
              </Title2>
            </Link>
          ))}
        </Left>
        <Right>
          {donors.at(activeIndex.donor).donors.map((donor) => (
            <DonorWrapper>
              <img src={donor.img} alt={donor.name} />
              <h3>{donor.name}</h3>
            </DonorWrapper>
          ))}
        </Right>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 150px);
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;
  & .left {
    background: #004600;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 60px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;
const Content = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 50px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  border-right: 1px solid lightgray;
`;
const Right = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  padding-left: 20px;
`;
const Title2 = styled.h1`
  font-family: "Anton,Sans serif";
  font-size: 17px;
  line-height: 22px;
  color: #000;
  transition: all 0.7s linear;
  position: relative;
  padding: 3px 0;
  cursor: pointer;
  white-space: nowrap;
  color: ${(props) => (props.active ? "brown" : "#000")};
  border-radius: 3px;
  margin: 2px;
  padding-left: 5px;
  &:hover {
    opacity: 0.8;
    color: brown;
    &::after {
      display: block;
    }
  }

  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0px;
    width: 3px;
    background: brown;
    border-radius: 2px;
    display: ${(props) => (props.active ? "block" : "none")};
  }
`;
const Title1 = styled.h4`
  margin-top: 20px;
  color: #004600;
  font-weight: 500;
`;

const DonorWrapper = styled.div`
  display: flex;
  align-items: center;

  flex-direction: column;
  border-radius: 5px;
  border: 1px solid brown;
  padding: 10px;
  min-width: 300px;
  &:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  img {
    width: 80%;
    object-fit: contain;
    height: 100px;
    margin: 0 auto;
  }
  h3 {
    color: brown;
  }
`;

export default Donors;
