export const ADD_TO_HOME_IMAGES_REQUEST = "ADD_TO_HOME_IMAGES_REQUEST";
export const ADD_TO_HOME_IMAGES_SUCCESS = "ADD_TO_HOME_IMAGES_SUCCESS";
export const ADD_TO_HOME_IMAGES_FAIL = "ADD_TO_HOME_IMAGES_FAIL";

export const GET_HOME_IMAGES_REQUEST = "GET_HOME_IMAGES_REQUEST";
export const GET_HOME_IMAGES_SUCCESS = "GET_HOME_IMAGES_SUCCESS";
export const GET_HOME_IMAGES_FAIL = "GET_HOME_IMAGES_FAIL";

export const DELETE_HOME_IMAGES_REQUEST = "DELETE_HOME_IMAGES_REQUEST";
export const DELETE_HOME_IMAGES_SUCCESS = "DELETE_HOME_IMAGES_SUCCESS";
export const DELETE_HOME_IMAGES_FAIL = "DELETE_HOME_IMAGES_FAIL";

export const UPDATE_HOME_IMAGES_REQUEST = "UPDATE_HOME_IMAGES_REQUEST";
export const UPDATE_HOME_IMAGES_SUCCESS = "UPDATE_HOME_IMAGES_SUCCESS";
export const UPDATE_HOME_IMAGES_FAIL = "UPDATE_HOME_IMAGES_FAIL";
