export const CREATE_NEWS_AND_EVENT_REQUEST = "CREATE_NEWS_AND_EVENT_REQUEST";
export const CREATE_NEWS_AND_EVENT_SUCCESS = "CREATE_NEWS_AND_EVENT_SUCCESS";
export const CREATE_NEWS_AND_EVENT_FAIL = "CREATE_NEWS_AND_EVENT_FAIL";
export const GET_NEWS_AND_EVENTS_REQUEST = "GET_NEWS_AND_EVENT_REQUEST";
export const GET_NEWS_AND_EVENTS_SUCCESS = "GET_NEWS_AND_EVENT_SUCCESS";
export const GET_NEWS_AND_EVENTS_FAIL = "GET_NEWS_AND_EVENT_FAIL";

export const GET_ALL_NEWS_AND_EVENTS_REQUEST = "GET_ALL_NEWS_AND_EVENT_REQUEST";
export const GET_ALL_NEWS_AND_EVENTS_SUCCESS = "GET_ALL_NEWS_AND_EVENT_SUCCESS";
export const GET_ALL_NEWS_AND_EVENTS_FAIL = "GET_ALL_NEWS_AND_EVENT_FAIL";

export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL";

export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAIL = "UPDATE_NEWS_FAIL";
