import { BiSolidChevronRight } from "react-icons/bi";
export const pageLinks = [
  { id: 0, name: "Home", url: "/" },
  {
    id: 1,
    icon: <BiSolidChevronRight color="#000" />,
    name: "About Us",
    links: [
      { id: 0, title: "Who We Are", url: "/who-we-are" },
      { id: 1, title: "Past Projects", url: "/past-projects" },
      { id: 2, title: "Staff", url: "/our-staff" },
      { id: 3, title: "Gallery", url: "/gallery" },
      { id: 4, title: "Facilities", url: "/our-facilities" },
    ],
  },
  {
    id: 2,
    name: "Publications",
    icon: <BiSolidChevronRight color="#000" />,
    publications: [
      { id: 0, title: "Annual Reports", url: "/annual-reports" },
      { id: 1, title: "Strategic Plans", url: "/strategic-plans" },
    ],
  },

  {
    id: 3,
    name: "Donors & Partners",
    icon: <BiSolidChevronRight color="#000" />,
    donors: [
      { id: 0, title: "Core Donors", url: "/core-donors" },
      { id: 1, title: "Project Donors", url: "/project-donors" },
      { id: 2, title: "Research Partners", url: "/research-partners" },
      { id: 3, title: "Communities", url: "/communities" },
    ],
  },
  { id: 4, name: "News & Events", url: "/news-and-events" },
  { id: 5, name: "Contact Us", url: "/contact-us" },
];
export const adminPageLinks = [
  { id: 0, name: "Home", url: "/admin/admin-dashboard" },

  {
    id: 1,
    name: "Annual Reports",

    url: "/admin/admin-annual-reports",
  },
  {
    id: 2,
    name: "Strategic Plans",

    url: "/admin/admin-strategic-plans",
  },

  { id: 3, name: "Gallery", url: "/admin/admin-gallery" },
  { id: 4, name: "News & Events", url: "/admin/admin-news-and-events" },
  { id: 5, name: "Logout", url: "/admin/admin-signout" },
];
