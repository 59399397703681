import {
  ADD_TO_REPORTS_FAIL,
  ADD_TO_REPORTS_REQUEST,
  ADD_TO_REPORTS_SUCCESS,
  DELETE_REPORT_FAIL,
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_SUCCESS,
  GET_REPORTS_FAIL,
  GET_REPORTS_REQUEST,
  GET_REPORTS_SUCCESS,
  UPDATE_REPORT_FAIL,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
} from "../constants/reportConstants";

import axios from "../http-file";
export const addToReports = (report) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TO_REPORTS_FAIL,
    payload: "",
  });
  dispatch({
    type: ADD_TO_REPORTS_SUCCESS,
    payload: "",
  });
  dispatch({ type: ADD_TO_REPORTS_REQUEST });
  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.post("/annual-reports", report, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: ADD_TO_REPORTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADD_TO_REPORTS_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const getReports = () => async (dispatch, getState) => {
  dispatch({
    type: GET_REPORTS_FAIL,
    payload: "",
  });
  dispatch({ type: GET_REPORTS_REQUEST });
  try {
    const { data } = await axios.get("/annual-reports");
    dispatch({ type: GET_REPORTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_REPORTS_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const deleteReport= (itemId) => async (dispatch, getState) => {
  dispatch({ type: DELETE_REPORT_FAIL, payload: null });
  dispatch({ type: DELETE_REPORT_SUCCESS, payload: null });
  dispatch({ type: DELETE_REPORT_REQUEST });

  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.delete(`/annual-reports/${itemId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: DELETE_REPORT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_REPORT_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
export const updateReport =
  (itemId, update) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_REPORT_FAIL, payload: null });
    dispatch({ type: UPDATE_REPORT_SUCCESS, payload: null });
    dispatch({ type: UPDATE_REPORT_REQUEST });

    try {
      const token = getState()?.signIn?.userInfo?.token;
      const { data } = await axios.put(`/annual-reports/${itemId}`, update, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: UPDATE_REPORT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: UPDATE_REPORT_FAIL,
        payload:
          error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message,
      });
    }
  };
