import {
  ACTIVE_ABOUT,
  ACTIVE_ADMIN_TAB,
  ACTIVE_DONOR,
  ACTIVE_PUBLICATION,
  ACTIVE_TAB,
  OPEN_ADMIN_SIDEBAR,
  OPEN_SIDEBAR,
} from "../constants/activeTabConstants";

export const changeActiveTab = (index) => (dispatch) => {
  dispatch({ type: ACTIVE_TAB, payload: index });
};
export const changeActiveAdminTab = (index) => (dispatch) => {
  dispatch({ type: ACTIVE_ADMIN_TAB, payload: index });
};
export const changeActiveAbout = (index) => (dispatch) => {
  dispatch({ type: ACTIVE_ABOUT, payload: index });
};
export const changeActivePublication = (index) => (dispatch) => {
  dispatch({ type: ACTIVE_PUBLICATION, payload: index });
};
export const changeActiveDonor = (index) => (dispatch) => {
  dispatch({ type: ACTIVE_DONOR, payload: index });
};

export const openSidebar = (open) => (dispatch) => {
  dispatch({ type: OPEN_SIDEBAR, payload: open });
};
export const openAdminSidebar = (open) => (dispatch) => {
  dispatch({ type: OPEN_ADMIN_SIDEBAR, payload: open });
};
