import React, { useEffect } from "react";
import styled from "styled-components";

import { FaLongArrowAltRight } from "react-icons/fa";

import { Zoom } from "react-awesome-reveal";

const PastProjects = () => {
  useEffect(() => {
    document.title =
      "Our Past Projects - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);

  const projects = [
    {
      id: 0,
      title: "Building farmer capacity",
      body: "Building farmer capacity through development of farmer associations in Vihiga funded through RUFORUM-EU ACP Moi University.",
    },
    {
      id: 1,
      title: "USAID-KHCP",
      body: "Promoting fruit and vegetable productivity in Busia and Siaya Counties-2011 September-2014 August 2014.----Is a project funded by USAID-KHCP and aims at improving food and income security, through production of fruits and vegetables for both food and income.",
    },
    {
      id: 2,
      title: "N2 AFRICA",
      body: "The project is ongoing and was previously funded by CIAT, but currently funded by IITA to continue on its research and information dissemination on Nitrogen fixation by use of legumes especially Soya beans.",
    },
    {
      id: 3,
      title: "Multipurpose grain legume (MLP)",
      body: "Is a research based project researching on various improved beans varieties from KARI and also local ones to test on resilience on pests, diseases and also weather conditions.",
    },
    {
      id: 4,
      title: "Grain Legume Project",
      body: "Funded by AGRA the project reached 15000 farmers within Siaya and Busia Counties and it dealt with upscalling production of grain legume crops (cowpeas, groundnuts and soya beans) to replenish soil fertility and increase incomes among smallholder farmers.",
    },
    {
      id: 1,
      title: "Integrated Striga management project (ISMA)",
      body: "The project was funded by AATF through Maseno University, the project was implemented in Teso south Alupe, Asinge and Siaya areas and targeted 2000 farmers.",
    },
  ];

  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Past Projects" />
      </head>
      <Content>
        <TopBanner>
          <div className="left">
            <h1>Past Projects</h1>
          </div>
          <div className="right">
            <img
              src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
              alt="Farm"
            />
          </div>
        </TopBanner>

        <Section1 className="wrapper">
          <Title3>
            ARDAP brings a wealth of experience and expertise in
            commercially-oriented smallholder agriculture, having actively
            engaged in agricultural development projects for the past fifteen
            years. Operating in Busia, Bungoma, Kakamega, and Siaya Counties,
            ARDAP focuses on a diverse range of crops, including grain legumes,
            root and tuber crops, cereals like maize and sorghum, as well as
            bananas, African leafy vegetables, and various fodder types.
            <br /> In 2012, ARDAP adopted an agri-business concept to address
            post-harvest losses, enhance nutrition, create collective volumes
            for economic marketing, and improve incomes. Notable outcomes
            include the establishment of farmer collecting units, an
            agro-processing center equipped with an eight-tonne rice milling
            machine, flour milling, and a water bottling unit. This initiative
            has successfully formed a network of over 5000 business units,
            contributing to the supply chain, middle-level processing, and
            delivery of ready products.
            <br />
            <br />
            Additionally, ARDAP has set up an Agro Ecology Centre, offering
            practical learning sessions on farm productivity, water-use
            efficiency, and soil health, while also demonstrating advanced
            technologies such as drip irrigation and shade nets.
            <br />
            <br /> Another key initiative involves the acquisition and
            distribution of crop germplasm, wherein ARDAP has established a seed
            system for horticultural crops, fostering public-private
            partnerships with input suppliers, TC material labs, and farm input
            advisory services. The seed system can support 5000 farmers with
            clean planting material, facilitating projects like cassava
            multiplication, sweet potato seed multiplication, forage
            multiplication, indigenous vegetable seed multiplication, kales
            nursery seedling propagation, and passion seedling propagation.
            <br />
            <br />
            Overall, ARDAP's past projects showcase a commitment to sustainable
            agriculture, innovation, and empowering smallholder farmers.
          </Title3>
          <Title5>
            Specific projects Completed in three geographical areas.
          </Title5>
          <Zoom cascade damping={0.3} triggerOnce={true}>
            {projects.map((item) => (
              <HorizontalWrapper key={item.id}>
                <Title4>
                  <FaLongArrowAltRight
                    style={{ marginRight: "8px" }}
                    color="brown"
                  />
                  {item.title}
                </Title4>
                <Title6>{item.body}</Title6>
              </HorizontalWrapper>
            ))}
          </Zoom>
        </Section1>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
  min-height: calc(100vh - 150px);
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;

  & .left {
    background: #004600;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 60px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;

const HorizontalWrapper = styled.div`
  display: flex;
  width: 100%;

  margin-bottom: 15px;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;
const Section1 = styled.div`
  display: flex;

  width: 100%;
  padding: 30px;
  flex-direction: column;
  box-shadow: 0 5px 5px #d9d9d9;
  margin-bottom: 50px;
`;
const Title5 = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
  text-decoration: underline;
`;
const Title4 = styled.h1`
  color: brown;
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
  display: flex;
  text-align: center;

  height: fit-content;
  align-items: center;
  margin-right: 8px;
`;
const Title3 = styled.h1`
  color: #000;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
`;
const Title6 = styled.h1`
  color: #000;
  font-weight: 400;
  font-size: 19px;

  line-height: 30px;
`;

export default PastProjects;
