import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FaFilePdf } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux";

import { useIsMount } from "../../hooks/useIsMount";
import { toast } from "react-toastify";
import { getReports } from "../../redux/actions/reportActions";
import Loader from "../../components/Loader";
import ConfirmReportDelete from "../../components/admin/ConfirmReportDelete";
import Modal from "../../components/admin/Modal";
import SignupButton from "../../components/SignupButton";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/admin/Navbar";
const AdminReports = () => {
  const { reports, loading, error } = useSelector((state) => state.getReports);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReports());
    // eslint-disable-next-line
  }, []);

  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (error) {
        errorExists(error);
      }
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    document.title =
      "Admin annual reports - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const [itemId, setItemId] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const handleDelete = (_id) => {
    setItemId(_id);
    setShowDelete(true);
  };
  const { userInfo } = useSelector((state) => state.signIn);

  const navigate = useNavigate();
  useEffect(() => {
    if (!userInfo?.user) {
      navigate("/admin/admin-signin");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Navbar />
      <TopBanner>
        <div className="left">
          <h1>Annual reports</h1>
        </div>
        <div className="right">
          <img src={`${process.env.PUBLIC_URL}/six.png`} alt="Farm" />
        </div>
      </TopBanner>

      <Content className="wrapper">
        <Left>
          <Title2 active={true}>Annual reports</Title2>
          <Link to="/admin/admin-add-annual-report">
            <SignupButton title="Add New" style={{ marginTop: "10px" }} />
          </Link>
        </Left>
        <Right>
          {loading ? (
            <Loader />
          ) : reports?.length === 0 ? (
            <h1 style={{ marginTop: "40px" }}>
              There are no Annual reports to show!
            </h1>
          ) : (
            <ReportsList>
              {reports?.map((report) => (
                <SingleItem>
                  <PDFWrapper>
                    <FaFilePdf color="brown" />
                    <PDFLink
                      href={report?.pdf?.url}
                      target="_blank"
                      type="application/pdf"
                    >
                      {report.caption}
                    </PDFLink>
                  </PDFWrapper>
                  <DeleteButton onClick={() => handleDelete(report._id)}>
                    Delete
                  </DeleteButton>
                </SingleItem>
              ))}
            </ReportsList>
          )}
        </Right>
      </Content>
      {showDelete && (
        <Modal>
          <ConfirmReportDelete setShowDelete={setShowDelete} itemId={itemId} />
        </Modal>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;

  & .left {
    background-color: #004600;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;
const Content = styled.div`
  display: flex;
  margin-top: 20px;
`;
const ReportsList = styled.div`
  display: flex;
  flex-direction: column;
`;
const DeleteButton = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  font-family: "Dm Sans";
  background: red;
  border-radius: 10px;
  padding: 7px 20px;
  color: #ffffff;
  cursor: pointer;
  width: fit-content;
  margin-top: 7px;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  border-right: 1px solid lightgray;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-left: 20px;
  margin-top: 10px;
`;
const Title2 = styled.h1`
  font-family: "Anton,Sans serif";
  font-size: 17px;
  line-height: 22px;
  color: #000;
  transition: all 0.7s linear;
  position: relative;
  padding: 3px 0;

  white-space: nowrap;
  cursor: pointer;
  color: ${(props) => (props.active ? "brown" : "#000")};
  border-radius: 3px;
  margin: 2px;
  padding-left: 5px;
  &:hover {
    opacity: 0.8;
    color: brown;
    &::after {
      display: block;
    }
  }

  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0px;
    width: 3px;
    background: brown;
    border-radius: 2px;
    display: ${(props) => (props.active ? "block" : "none")};
  }
`;

const PDFWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SingleItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
  padding: 5px 0;
`;
const PDFLink = styled.a`
  color: brown;
  font-size: 12px;
  padding-left: 5px;
  transition: all 0.6s linear;
  &:hover {
    text-decoration: underline;
  }
`;
export default AdminReports;
