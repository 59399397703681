import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Loader from "../../components/Loader";

import { getHomeImages } from "../../redux/actions/homeImagesActions";
import { toast } from "react-toastify";
import { useIsMount } from "../../hooks/useIsMount";
import Navbar from "../../components/admin/Navbar";
import HomePageItem from "../../components/admin/HomePageItem";
import Modal from "../../components/admin/Modal";
import ConfirmHIDelete from "../../components/admin/ConfirmHIDelete";
const Admin = () => {
  const { images, loading, error } = useSelector(
    (state) => state.getHomeImages
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHomeImages());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    document.title =
      "Admin dashboard - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (error) {
        errorExists(error);
      }
    }
    // eslint-disable-next-line
  }, [error]);
  const [itemId, setItemId] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const { userInfo } = useSelector((state) => state.signIn);

  const navigate = useNavigate();
  useEffect(() => {
    if (!userInfo?.user) {
      navigate("/admin/admin-signin");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Navbar />
      <ContentWrapper className="wrapper">
        <ImagesWrapper>
          <div className="container">
            <Title>Home page images</Title>
            <Link to="/admin/admin-add-home-images">
              <Button>Add Images</Button>
            </Link>
          </div>
          <Link to="/">
            <Button>View Website</Button>
          </Link>

          {loading ? (
            <Loader />
          ) : images?.length === 0 ? (
            <h1 style={{ marginTop: "40px" }}>There are no images to show!</h1>
          ) : (
            <ImagesList>
              {images?.map((image) => (
                <HomePageItem
                  key={image._id}
                  {...image}
                  setShowDelete={setShowDelete}
                  setItemId={setItemId}
                />
              ))}
            </ImagesList>
          )}
        </ImagesWrapper>
      </ContentWrapper>
      {showDelete && (
        <Modal>
          <ConfirmHIDelete setShowDelete={setShowDelete} itemId={itemId} />
        </Modal>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  width: 100vw;
  align-items: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 50px;
`;
const Button = styled.button`
  width: 167px;
  height: 50px;

  background: #004600;

  border: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.5s linear;
  &:hover {
    border-radius: 26px;
    color: #e5e5e5;
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  overflow-y: scroll;

  height: 100%;
  .container {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
  }
`;
const ImagesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
`;
const Title = styled.h1`
  display: flex;
  align-items: center;
  font-family: "Dm Sans";
  margin-top: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  color: #0d0d2b;
  @media screen and (max-width: 700px) {
    font-size: 17px;
  }
`;

export default Admin;
