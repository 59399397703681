import {
  CREATE_NEWS_AND_EVENT_FAIL,
  CREATE_NEWS_AND_EVENT_REQUEST,
  CREATE_NEWS_AND_EVENT_SUCCESS,
  DELETE_NEWS_FAIL,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  GET_ALL_NEWS_AND_EVENTS_FAIL,
  GET_ALL_NEWS_AND_EVENTS_REQUEST,
  GET_ALL_NEWS_AND_EVENTS_SUCCESS,
  GET_NEWS_AND_EVENTS_FAIL,
  GET_NEWS_AND_EVENTS_REQUEST,
  GET_NEWS_AND_EVENTS_SUCCESS,
  UPDATE_NEWS_FAIL,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
} from "../constants/newsConstants";

export const createNewsAndEventsReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEWS_AND_EVENT_REQUEST:
      return { ...state, loading: true };
    case CREATE_NEWS_AND_EVENT_SUCCESS:
      return { ...state, loading: false, added: action.payload };
    case CREATE_NEWS_AND_EVENT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getNewsAndEventsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NEWS_AND_EVENTS_REQUEST:
      return { ...state, loading: true };
    case GET_NEWS_AND_EVENTS_SUCCESS:
      return { ...state, loading: false, newsAndEvents: action.payload };
    case GET_NEWS_AND_EVENTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAllNewsAndEventsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_NEWS_AND_EVENTS_REQUEST:
      return { ...state, loading: true };
    case GET_ALL_NEWS_AND_EVENTS_SUCCESS:
      return { ...state, loading: false, newsAndEvents: action.payload };
    case GET_ALL_NEWS_AND_EVENTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_NEWS_REQUEST:
      return { ...state, loading: true };
    case DELETE_NEWS_SUCCESS:
      return { ...state, loading: false, deleted: action.payload };
    case DELETE_NEWS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_NEWS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_NEWS_SUCCESS:
      return { ...state, loading: false, updated: action.payload };
    case UPDATE_NEWS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
