import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SingleNews = ({ image, title, description, author, createdAt, _id }) => {
  return (
    <Link to={`/news-and-events/${_id}`}>
      <ItemWrapper>
        <Image src={image?.url} />
        <Caption>{title.substring(0, 100)}</Caption>
        <Author>{`By: ${author} On: ${new Date(createdAt).getDate()}-${
          new Date(createdAt).getMonth() + 1
        }-${new Date(createdAt).getFullYear()}`}</Author>
        <Description>{description.substring(0, 200)}</Description>
        <Buttons>
          <Link to={`/news-and-events/${_id}`}></Link>
        </Buttons>
      </ItemWrapper>
    </Link>
  );
};
const ItemWrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: 270px;
  max-width: 470px;
  flex-direction: column;
  overflow: hidden;

  padding-bottom: 14px;
  &:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  transition: all 0.7s linear;
  &:hover {
    opacity: 0.8;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const Caption = styled.h1`
  color: #000;
  font-size: 17px;
  padding: 0 14px;
  position: relative;
`;
const Description = styled.h1`
  color: #000;
  font-size: 15px;
  padding: 0 14px;
  font-style: italic;
  margin: 10px 0;
`;
const Author = styled.h1`
  color: #000;
  font-size: 15px;
  padding: 7px 14px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  margin-top: 5px;
`;

export default SingleNews;
