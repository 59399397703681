export const ADD_TO_GALLERY_REQUEST = "ADD_TO_GALLERY_REQUEST";
export const ADD_TO_GALLERY_SUCCESS = "ADD_TO_GALLERY_SUCCESS";
export const ADD_TO_GALLERY_FAIL = "ADD_TO_GALLERY_FAIL";

export const GET_GALLERY_REQUEST = "GET_GALLERY_REQUEST";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAIL = "GET_GALLERY_FAIL";


export const DELETE_GALLERY_IMAGES_REQUEST = "DELETE_GALLERY_IMAGES_REQUEST";
export const DELETE_GALLERY_IMAGES_SUCCESS = "DELETE_GALLERY_IMAGES_SUCCESS";
export const DELETE_GALLERY_IMAGES_FAIL = "DELETE_GALLERY_IMAGES_FAIL";

export const UPDATE_GALLERY_IMAGES_REQUEST = "UPDATE_GALLERY_IMAGES_REQUEST";
export const UPDATE_GALLERY_IMAGES_SUCCESS = "UPDATE_GALLERY_IMAGES_SUCCESS";
export const UPDATE_GALLERY_IMAGES_FAIL = "UPDATE_GALLERY_IMAGES_FAIL";