import React from "react";
import styled from "styled-components";

const FacilityItem = ({ image, title }) => {
  return (
    <Wrapper>
      <Image src={image} />
      <Caption>{title}</Caption>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: 270px;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid brown;
  border-radius: 10px;
  padding-bottom: 14px;
  &:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  transition: all 0.7s linear;
  &:hover {
    opacity: 0.8;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const Caption = styled.h1`
  color: #000;
  font-size: 17px;
  padding: 0 14px;
`;

export default FacilityItem;
