import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  changeActiveAdminTab,
  openAdminSidebar,
} from "../../redux/actions/activeTabActions";
import { adminPageLinks } from "../../data/pageLinks";
const Navbar = () => {
  const logo = process.env.PUBLIC_URL + "/LOGO.jpeg";
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 500;
      if (scrollY > threshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const activeIndex = useSelector((state) => state.activeTab);
  const isSidebarOpen = useSelector((state) => state.sidebarOpen);
  const dispatch = useDispatch();

  const handleClick = (index) => {
    dispatch(changeActiveAdminTab(index));
  };

  const toggleSidebar = () => {
    dispatch(openAdminSidebar(!isSidebarOpen.adminOpen));
  };
  return (
    <Wrapper scrolled={isScrolled}>
      <Content className="wrapper">
        <Link to="/admin/admin-dashboard">
          <Logo src={logo} alt="Logo" />
        </Link>
        <Links>
          {adminPageLinks.map((pageLink, index) => (
            <Link to={pageLink.url}>
              <PageLink
                key={pageLink.id}
                active={index === (activeIndex.adminIndex || 0)}
                onClick={handleClick}
              >
                {pageLink.name}
              </PageLink>
            </Link>
          ))}
        </Links>

        <Bars onClick={toggleSidebar}>
          <Bar open={isSidebarOpen.adminOpen} />
          <Bar open={isSidebarOpen.adminOpen} />
          <Bar open={isSidebarOpen.adminOpen} />
        </Bars>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.nav`
  width: 100vw;
  background-color: #fff;
  z-index: 70;
  /* position: ${({ scrolled }) =>
    scrolled === true ? "sticky" : "relative"}; */
  position: sticky;
  top: 0;
  transition: all 2s linear;
  box-shadow: 0 0 8px #999;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85px !important;
  @media screen and (max-width: 800px) {
    height: 70px;
  }
`;
const Logo = styled.img`
  width: 70px;
  height: 70px;
  cursor: pointer;
  object-fit: cover;
  @media screen and (max-width: 800px) {
    width: 63px;
    height: 63px;
  }
`;
const Links = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const PageLink = styled.div`
  font-family: "Anton,Sans serif";
  font-style: bold;
  font-weight: 800;
  height: 100%;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => (props.active ? "brown" : "#000")};
  margin-right: 40px;
  transition: all 0.5s linear;
  position: relative;
  padding: 20px 0;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    height: 4px;
    bottom: 5px;
    left: 0px;
    right: 0px;
    background: brown;
    border-radius: 2px;
    display: ${(props) => (props.active ? "block" : "none")};
  }
  &:hover {
    color: #004600;

    &::after {
      display: block;
    }
  }
`;

const Bars = styled.div`
  cursor: pointer;
  padding: 10px 0;
  margin-right: 20px;
  transition: all 0.6s linear;
  display: flex;
  position: relative;
  width: 40px;
  height: 30px;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 800px) {
    display: none;
  }
  &:hover {
    opacity: 0.7;
    transform: scale(1.1);
  }
`;
const Bar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #141414;
  border-radius: 3px;
  position: absolute;

  &:first-child {
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0deg)")};
    top: ${({ open }) => (open ? "13px" : "0")};
  }
  &:nth-child(3) {
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0deg)")};
    bottom: ${({ open }) => (open ? "13px" : "0")};
  }
  &:nth-child(2) {
    display: ${({ open }) => (open ? "none" : "block")};
    transform: translateY(-50%);
    top: 50%;
  }
  transition: all 0.7s linear;
`;

export default Navbar;
