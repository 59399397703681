import {
  ACTIVE_ABOUT,
  ACTIVE_ADMIN_TAB,
  ACTIVE_DONOR,
  ACTIVE_PUBLICATION,
  ACTIVE_TAB,
  OPEN_ADMIN_SIDEBAR,
  OPEN_SIDEBAR,
} from "../constants/activeTabConstants";

export const activeTabReducer = (state = {}, action) => {
  if (action.type === ACTIVE_TAB) {
    return { ...state, index: action.payload };
  } else if (action.type === ACTIVE_ABOUT) {
    return { ...state, about: action.payload };
  } else if (action.type === ACTIVE_PUBLICATION) {
    return { ...state, publication: action.payload };
  } else if (action.type === ACTIVE_DONOR) {
    return { ...state, donor: action.payload };
  }
  if (action.type === ACTIVE_ADMIN_TAB) {
    return { ...state, adminIndex: action.payload };
  }
  return state;
};
export const sidebarOpenReducer = (state = {}, action) => {
  if (action.type === OPEN_SIDEBAR) {
    return { ...state, open: action.payload };
  } else if (action.type === OPEN_ADMIN_SIDEBAR) {
    return { ...state, adminOpen: action.payload };
  }
  return state;
};
