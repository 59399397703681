import "./App.css";
import {
  Route,
  Routes,
  HashRouter as Router,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";

import { useEffect } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  changeActiveAbout,
  changeActiveTab,
  changeActivePublication,
  changeActiveDonor,
  changeActiveAdminTab,
} from "./redux/actions/activeTabActions";
import { useDispatch } from "react-redux";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Publications from "./pages/Publications";
import Donors from "./pages/Donors";
import WhoWeAre from "./pages/WhoWeAre";
import PastProjects from "./pages/PastProjects";
import Contact from "./pages/Contact";
import Staff from "./pages/Staff";
import Gallery from "./pages/Gallery";
import Facilities from "./pages/Facilities";
import NewsAndEvents from "./pages/NewsAndEvents";
import Login from "./pages/admin/Login";

import AddToGallery from "./pages/admin/AddToGallery";
import Logout from "./pages/admin/Logout";
import Admin from "./pages/admin/Admin";
import AddHomeImages from "./pages/admin/AddHomeImages";
import UpdateHomeImage from "./pages/admin/UpdateHomeImage";
import AdminSidebar from "./components/admin/AdminSidebar";
import AdminGallery from "./pages/admin/AdminGallery";
import AddAnnualReport from "./pages/admin/AddAnnualReport";

import AdminReports from "./pages/admin/AdminReports";
import AddPlan from "./pages/admin/AddPlan";
import AdminPlans from "./pages/admin/AdminPlans";
import AddNewsAndEvents from "./pages/admin/AddNewsAndEvents";
import AdminNewsAndEvents from "./pages/admin/AdminNewsAndEvents";
import AdminNewsAndEventsDetails from "./pages/admin/AdminNewsAndEventsDetails";
import NewsAndEventsDetails from "./pages/NewsAndEventsDetails";
import GalleryDetails from "./pages/GalleryDetails";
import AdminGalleryDetails from "./pages/admin/AdminGalleryDetails";
import Footer from "./components/Footer";
const MyComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        dispatch(changeActiveTab(0));
        break;
      case "/who-we-are":
        dispatch(changeActiveTab(1));
        dispatch(changeActiveAbout(0));
        break;

      case "/past-projects":
        dispatch(changeActiveTab(1));
        dispatch(changeActiveAbout(1));
        break;
      case "/our-staff":
        dispatch(changeActiveTab(1));
        dispatch(changeActiveAbout(2));
        break;
      case "/gallery":
        dispatch(changeActiveTab(1));
        dispatch(changeActiveAbout(3));
        break;
      case "/our-facilities":
        dispatch(changeActiveTab(1));
        dispatch(changeActiveAbout(4));
        break;
      case "/contact-us":
        dispatch(changeActiveTab(5));
        break;

      default:
        dispatch(changeActiveTab(0));
        break;
    }
    if (location.pathname.startsWith("/publications")) {
      dispatch(changeActiveTab(2));
      switch (decodeURIComponent(location.pathname.split("/")[2])) {
        case "annual-reports":
          dispatch(changeActivePublication(0));
          break;
        case "strategic-plans":
          dispatch(changeActivePublication(1));
          break;
        default:
          break;
      }
    }

    if (location.pathname.startsWith("/news-and-events")) {
      dispatch(changeActiveTab(4));
    }
    if (location.pathname.startsWith("/donors-and-partners")) {
      dispatch(changeActiveTab(3));
      switch (decodeURIComponent(location.pathname.split("/")[2])) {
        case "core-donors":
          dispatch(changeActiveDonor(0));
          break;
        case "project-donors":
          dispatch(changeActiveDonor(1));
          break;
        case "research-partners":
          dispatch(changeActiveDonor(2));
          break;
        case "private-sector-partners":
          dispatch(changeActivePublication(3));
          break;
        case "communities":
          dispatch(changeActivePublication(4));
          break;

        default:
          break;
      }
    }
    if (location.pathname.startsWith("/admin")) {
      switch (decodeURIComponent(location.pathname.split("/")[2])) {
        case "admin-dashboard":
          dispatch(changeActiveAdminTab(0));
          break;
        case "admin-annual-reports":
          dispatch(changeActiveAdminTab(1));
          break;
        case "admin-strategic-plans":
          dispatch(changeActiveAdminTab(2));
          break;
        case "admin-gallery":
          dispatch(changeActiveAdminTab(3));
          break;
        case "admin-news-and-events":
          dispatch(changeActiveAdminTab(4));
          break;

        default:
          break;
      }
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname, dispatch]);
  const isAdminPage = location.pathname.startsWith("/admin");

  return (
    <>
      {!isAdminPage && <Navbar />}

      <Sidebar />
      <AdminSidebar />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/past-projects" element={<PastProjects />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:id" element={<GalleryDetails />} />
        <Route path="/news-and-events" element={<NewsAndEvents />} />
        <Route path="/news-and-events/:id" element={<NewsAndEventsDetails />} />
        <Route path="/our-staff" element={<Staff />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/our-facilities" element={<Facilities />} />
        <Route path="/publications/:publication" element={<Publications />} />
        <Route path="/donors-and-partners/:donor" element={<Donors />} />

        <Route path="/admin/admin-signin" element={<Login />} />
        <Route path="/admin/admin-signout" element={<Logout />} />
        {/* <Route path="/admin/admin-signup" element={<Register />} /> */}
        <Route path="/admin/admin-gallery" element={<AdminGallery />} />
        <Route
          path="/admin/admin-gallery/:id"
          element={<AdminGalleryDetails />}
        />
        <Route path="/admin/add-to-gallery" element={<AddToGallery />} />
        <Route path="/admin/admin-dashboard" element={<Admin />} />
        <Route
          path="/admin/admin-add-home-images"
          element={<AddHomeImages />}
        />
        <Route
          path="/admin/admin-update-home-images/:id"
          element={<UpdateHomeImage />}
        />
        <Route path="/admin/admin-add-strategic-plans" element={<AddPlan />} />
        <Route path="/admin/admin-strategic-plans" element={<AdminPlans />} />
        <Route
          path="/admin/admin-add-annual-report"
          element={<AddAnnualReport />}
        />
        <Route path="/admin/admin-annual-reports" element={<AdminReports />} />
        <Route
          path="/admin/admin-update-annual-report/:id"
          element={<UpdateHomeImage />}
        />

        <Route
          path="/admin/admin-news-and-events"
          element={<AdminNewsAndEvents />}
        />

        <Route
          path="/admin/admin-news-and-events/:id"
          element={<AdminNewsAndEventsDetails />}
        />

        <Route
          path="/admin/admin-add-news-and-events"
          element={<AddNewsAndEvents />}
        />
      </Routes>
      {!isAdminPage && <Footer />}
    </>
  );
};
function App() {
  return (
    <Router>
      <ToastContainer />
      <MyComponent />
    </Router>
  );
}

export default App;
