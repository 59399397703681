import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FaFilePdf } from "react-icons/fa6";
import { changeActivePublication } from "../redux/actions/activeTabActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getReports } from "../redux/actions/reportActions";
import { getPlans } from "../redux/actions/planActions";
import { toast } from "react-toastify";
import { useIsMount } from "../hooks/useIsMount";
import Loader from "../components/Loader";
const Publications = () => {
  const dispatch = useDispatch();
  const handlePublicationClick = (index) => {
    dispatch(changeActivePublication(index));
  };
  const publications = [
    { id: 0, title: "Annual Reports", url: "/annual-reports" },
    { id: 1, title: "Strategic Plans", url: "/strategic-plans" },
  ];
  const activeIndex = useSelector((state) => state.activeTab);
  useEffect(() => {
    document.title =
      "Publications - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const reportsState = useSelector((state) => state.getReports);
  const plansState = useSelector((state) => state.getPlans);

  useEffect(() => {
    dispatch(getReports());
    dispatch(getPlans());
    // eslint-disable-next-line
  }, []);

  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (plansState?.error) {
        errorExists(plansState.error);
      }
      if (reportsState?.error) {
        errorExists(reportsState.error);
      }
    }
    // eslint-disable-next-line
  }, [reportsState.error, plansState.error]);
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[2];
  const [isReports, setReports] = useState(
    currentLocation === "annual-reports"
  );

  useEffect(() => {
    setReports(currentLocation === "annual-reports");
  }, [currentLocation]);
  return (
    <Wrapper>
      <TopBanner>
        <div className="left">
          <h1>{publications.at(activeIndex.publication)?.title}</h1>
        </div>
        <div className="right">
          <img
            src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
            alt="Farm"
          />
        </div>
      </TopBanner>
      <Title1 className="wrapper">
        <Title1 className="wrapper">
          {`Home > Donors & Partners > ${
            publications.at(activeIndex.publication)?.title
          }`}
        </Title1>
      </Title1>
      <Content className="wrapper">
        <Left>
          {publications.map((publication, index) => (
            <Link to={`/publications${publication.url}`}>
              <Title2
                key={publication.id}
                active={index === activeIndex.publication}
                onClick={() => handlePublicationClick(index)}
              >
                {publication.title}
              </Title2>
            </Link>
          ))}
        </Left>
        <Right>
          {isReports ? (
            <>
              {reportsState?.loading ? (
                <Loader />
              ) : reportsState?.reports?.length === 0 ? (
                <h1 style={{ marginTop: "40px" }}>
                  There are no Annual reports to show!
                </h1>
              ) : (
                <>
                  {reportsState?.reports?.map((report) => (
                    <PDFWrapper>
                      <FaFilePdf color="brown" />
                      <PDFLink
                        href={report.pdf.url}
                        target="_blank"
                        type="application/pdf"
                      >
                        {report.caption}
                      </PDFLink>
                    </PDFWrapper>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              {plansState?.loading ? (
                <Loader />
              ) : plansState?.plans?.length === 0 ? (
                <h1 style={{ marginTop: "40px" }}>
                  There are no Strategic plans to show!
                </h1>
              ) : (
                <>
                  {plansState?.plans?.map((report) => (
                    <PDFWrapper>
                      <FaFilePdf color="brown" />
                      <PDFLink
                        href={report.pdf.url}
                        target="_blank"
                        type="application/pdf"
                      >
                        {report.caption}
                      </PDFLink>
                    </PDFWrapper>
                  ))}
                </>
              )}
            </>
          )}
        </Right>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 150px);
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;

  & .left {
    background-color: #004600;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;
const Content = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  border-right: 1px solid lightgray;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-left: 20px;
  margin-top: 10px;
`;
const Title2 = styled.h1`
  font-family: "Anton,Sans serif";
  font-size: 17px;
  line-height: 22px;
  color: #000;
  transition: all 0.7s linear;
  position: relative;
  padding: 3px 0;
  white-space: nowrap;
  cursor: pointer;
  color: ${(props) => (props.active ? "brown" : "#000")};
  border-radius: 3px;
  margin: 2px;
  padding-left: 5px;
  &:hover {
    opacity: 0.8;
    color: brown;
    &::after {
      display: block;
    }
  }

  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0px;
    width: 3px;
    background: brown;
    border-radius: 2px;
    display: ${(props) => (props.active ? "block" : "none")};
  }
`;
const Title1 = styled.h4`
  margin-top: 20px;
  color: #004600;
  font-weight: 500;
`;
const PDFWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const PDFLink = styled.a`
  color: brown;
  font-size: 12px;
  padding-left: 5px;
  transition: all 0.6s linear;
  &:hover {
    text-decoration: underline;
  }
`;
export default Publications;
