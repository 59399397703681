import React, { useEffect, useState } from "react";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Loader from "../components/Loader";

import { useIsMount } from "../hooks/useIsMount";

import { getNewsAndEvents } from "../redux/actions/newsActions";
import SingleNews from "../components/SingleNews";
const NewsAndEvents = () => {
  const { loading, newsAndEvents, error } = useSelector(
    (state) => state.getNews
  );
  const [page, setPage] = useState(0);
  useEffect(() => {
    document.title =
      "News And Events - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const isMount = useIsMount();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsAndEvents(page));
    // eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    if (!isMount) {
      alert(error);
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <Wrapper>
      <TopBanner>
        <div className="left">
          <h1>News And Events</h1>
        </div>
        <div className="right">
          <img
            src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
            alt="Farm"
          />
        </div>
      </TopBanner>
      {loading ? (
        <Loader />
      ) : (
        <NewsAndEventsWrapper>
          {newsAndEvents?.events?.map((newsAndEvent) => (
            <Link to={`/newsAndEvents/${newsAndEvent._id}`}>
              <SingleNews key={newsAndEvent._id} {...newsAndEvent} />
            </Link>
          ))}
        </NewsAndEventsWrapper>
      )}
      <Buttons>
        <Button
          onClick={() => setPage(page - 1)}
          disabled={page === 0 ? true : false}
        >
          Previous page
        </Button>
        <Button2
          onClick={() => setPage(page - 1)}
          disabled={page === 0 ? true : false}
        >
          <BsFillArrowLeftCircleFill />
        </Button2>

        <PageCount>
          <h1>{`Page ${page + 1}`}</h1>
          <h2>of</h2>
          <h1>{Math.ceil(newsAndEvents?.count / 12)}</h1>
        </PageCount>
        <Button
          onClick={() => setPage(page + 1)}
          disabled={
            page + 1 === Math.ceil(newsAndEvents?.count / 12) ? true : false
          }
        >
          Next page
        </Button>
        <Button2
          onClick={() => setPage(page + 1)}
          disabled={
            page + 1 === Math.ceil(newsAndEvents?.count / 12) ? true : false
          }
        >
          <BsFillArrowRightCircleFill />
        </Button2>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  height: 100vh;
  min-height: calc(100vh - 150px);
  overflow-x: hidden;
  width: 100%;
  align-items: flex-start;
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;

  & .left {
    background: #004600;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 60px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;
const NewsAndEventsWrapper = styled.div`
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 30px 100px 50px;
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
  }
  @media screen and (max-width: 800px) {
    padding: 20px;
    grid-template-columns: 1fr;
  }
`;
const Button = styled.button`
  width: 152px;
  padding: 10px 25px;
  height: 40px;
  color: white;
  background: brown;

  border: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  @media screen and (max-width: 600px) {
    display: none;
  }
  transition: all 0.5s linear;
  &:hover {
    border-radius: 26px;
    color: #e5e5e5;
  }
  &:disabled {
    cursor: not-allowed;
    background: gray;
  }
`;

const Button2 = styled.button`
  width: 50px;
  padding: 10px 25px;
  height: 40px;
  color: white;
  background: brown;
  display: none;

  border: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  @media screen and (max-width: 600px) {
    /* display: block; */
    text-align: center;
    display: grid;
    place-items: center;
  }
  transition: all 0.5s linear;
  &:hover {
    border-radius: 26px;
    color: #e5e5e5;
  }
  &:disabled {
    cursor: not-allowed;
    background: gray;
  }
`;
const Buttons = styled.div`
  display: flex;
  padding: 100px;
  width: 100%;
  @media screen and (max-width: 800px) {
    padding: 20px;
  }
  justify-content: space-between;
`;
const PageCount = styled.div`
  display: flex;
  align-items: center;
  h1 {
    color: brown;
    font-size: 18px;
    font-style: bold;
    margin: 0 5px;
  }
  h2 {
    color: gray;
    font-size: 15px;
  }
`;

export default NewsAndEvents;
