import React, { useEffect, useState } from "react";
import { HiPhotograph } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import InputWithLabel from "../../components/InputWithLabel";
import { useIsMount } from "../../hooks/useIsMount";

import { toast } from "react-toastify";
import { addToHomeImages } from "../../redux/actions/homeImagesActions";

const AddHomeImages = () => {
  const [image, setImage] = useState();
  const [caption, setCaption] = useState();

  const { loading, images, error } = useSelector(
    (state) => state.addToHomeImages
  );
  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const success = (message) => {
    return toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (error) {
        errorExists(error);
      }
    }
    // eslint-disable-next-line
  }, [error]);
  useEffect(() => {
    if (!isMount) {
      
      if (images) {
       
        success("Image uploaded successfully!");
        setImage(null);
        setCaption("");
      }
    }
    // eslint-disable-next-line
  }, [images]);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (!caption || !image) {
      errorExists("Kindly fill all the fields!");
    }
    dispatch(addToHomeImages({ image, caption }));
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
    };
  };

  return (
    <Wrapper>
      <TopBanner>
        <div className="left">
          <h1>Add to Home Images</h1>
        </div>
        <div className="right">
          <img
            src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
            alt="Farm"
          />
        </div>
      </TopBanner>
      <ContentWrapper>
        <Link to="/admin/admin-dashboard">
          <Button back>Back</Button>
        </Link>
        <Card>
          <Title>Enter a caption then select an image.</Title>
          <InputWithLabel
            placeholder={`Image caption`}
            label="Image caption"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />
          <Title2>Select image below </Title2>

          {image && <Image src={image} />}
          <Label htmlFor="photo">
            <FileInput
              type="file"
              id="photo"
              accept=".png,.jpg,.jpeg"
              onChange={(e) => handleImage(e)}
            />
            <Icon id="photo">
              <HiPhotograph />
            </Icon>
          </Label>

          <Buttons>
            <Button
              onClick={handleClick}
              disabled={loading || !image || !caption}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
          </Buttons>
        </Card>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;

  flex-direction: column;
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;
  & .left {
    background: #004600;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 60px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  border-radius: 30px;
  background: #fff;
  height: fit-content;
  padding: 45px;
  width: 100%;
  max-width: 600px;
`;

const Label = styled.label`
  cursor: pointer;
  display: flex;
  position: relative;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  color: #000f2d;
  margin-left: 10px;
`;

const Image = styled.img`
  width: 90%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 10px;
`;
const Title = styled.h2`
  font-family: "DM Serif Display";
  font-style: bold;
  font-weight: 400;
  margin-bottom: 11px;
  font-size: 15px;
  line-height: 28px;
  color: #141414;
`;
const Title2 = styled.h1`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 28px;

  color: #141414;
`;
const FileInput = styled.input`
  display: none;
  background: #ffffff;
  border: 1.2px solid #f1f1f1;
  border-radius: 8px;
  align-self: flex-start;
  padding: 12px;

  width: 100%;

  margin-bottom: 20px;
  &:invalid {
    border-color: red;
  }
`;
const Buttons = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Button = styled.button`
  background: #004600;
  border-radius: 8px;
  border: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  /* width: 400px; */
  padding: 10px 40px;
  margin-right: 30px;
  margin-bottom: ${(props) => props.back && "20px"};
  cursor: pointer;
  transition: all 0.6s linear;
  &:disabled {
    cursor: not-allowed;
    background: gray;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export default AddHomeImages;
