export const ADD_TO_PLANS_REQUEST = "ADD_TO_PLANS_REQUEST";
export const ADD_TO_PLANS_SUCCESS = "ADD_TO_PLANS_SUCCESS";
export const ADD_TO_PLANS_FAIL = "ADD_TO_PLANS_FAIL";

export const GET_PLANS_REQUEST = "GET_PLANS_REQUEST";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAIL = "GET_PLANS_FAIL";

export const DELETE_PLAN_REQUEST = "DELETE_PLAN_REQUEST";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAIL = "DELETE_PLAN_FAIL";

export const UPDATE_PLAN_REQUEST = "UPDATE_PLAN_REQUEST";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL";
