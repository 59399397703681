import { combineReducers, compose, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";

import {
  activeTabReducer,
  sidebarOpenReducer,
} from "./reducers/activeTabReducers";
import { legacy_createStore } from "redux";
import {
  createNewsAndEventsReducer,
  deleteNewsReducer,
  getAllNewsAndEventsReducer,
  getNewsAndEventsReducer,
  updateNewsReducer,
} from "./reducers/newsReducers";
import { signUpReducer, signinReducer } from "./reducers/authReducers";
import {
  addToGalleryReducer,
  deleteGalleryImagesReducer,
  getGalleryReducer,
  updateGalleryImagesReducer,
} from "./reducers/galleryReducers";
import {
  addToHomeImagesReducer,
  deleteHomeImagesReducer,
  getHomeImagesReducer,
  updateHomeImagesReducer,
} from "./reducers/homeImagesReducers";
import {
  addToPlansReducer,
  deletePlanReducer,
  getPlansReducer,
  updatePlanReducer,
} from "./reducers/planReducers";
import {
  addToReportsReducer,
  deleteReportReducer,
  getReportsReducer,
  updateReportReducer,
} from "./reducers/reportReducers";

const initialState = {
  signIn: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
};

const reducers = combineReducers({
  activeTab: activeTabReducer,
  sidebarOpen: sidebarOpenReducer,
  createNewsAndEvents: createNewsAndEventsReducer,
  getNews: getNewsAndEventsReducer,
  getAllNews: getAllNewsAndEventsReducer,
  deleteNews: deleteNewsReducer,
  updateNews: updateNewsReducer,
  signIn: signinReducer,
  signUp: signUpReducer,
  addToGallery: addToGalleryReducer,
  getGallery: getGalleryReducer,
  deleteGalleryImages: deleteGalleryImagesReducer,
  updateGalleryImage: updateGalleryImagesReducer,
  addToHomeImages: addToHomeImagesReducer,
  getHomeImages: getHomeImagesReducer,
  deleteHomeImages: deleteHomeImagesReducer,
  updateHomeImage: updateHomeImagesReducer,
  addToPlans: addToPlansReducer,
  getPlans: getPlansReducer,
  deletePlan: deletePlanReducer,
  updatePlan: updatePlanReducer,
  addToReports: addToReportsReducer,
  getReports: getReportsReducer,
  deleteReport: deleteReportReducer,
  updateReport: updateReportReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = legacy_createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
