import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const WhoWeAre = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const baseDir = process.env.PUBLIC_URL;
  useEffect(() => {
    document.title =
      "Who We Are - Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const images = useMemo(
    () => [
      `${baseDir}/one.jpg`,
      `${baseDir}/two.png`,
      `${baseDir}/three.png`,
      `${baseDir}/four.png`,
      `${baseDir}/five.png`,
    ], // eslint-disable-next-line
    []
  );
  const objectives = [
    {
      id: 0,
      title:
        "1.	Provide a conducive working environment, positive image and resources for excellence service delivery.",
    },
    {
      id: 1,
      title:
        "Food secure communities through sustainable agriculture, strengthened seed systems, food value addition and enhanced marketing.",
    },
    {
      id: 2,
      title:
        "To empower vulnerable households in environmental health, microcredit and nutrition for social change and self reliance.",
    },
    {
      id: 3,
      title:
        "Deliver consistent agricultural information to the community, stakeholders, collaborators and organizations.",
    },
    {
      id: 4,
      title:
        "To build capacity through innovative training by designing, disseminating clientele oriented courses.",
    },
  ];
  let intervalRef = useRef(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(intervalRef.current);
  }, [images]);

  useEffect(() => {
    setTranslateX(-currentIndex * 100);
  }, [currentIndex]);

  const handleNext = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === images.length ? 0 : prevIndex + 1
    );
  };
  const handlePrevious = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
    );
  };
  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Who We Are" />
      </head>
      <Content>
        <TopBanner>
          <div className="left">
            <h1>Who We Are</h1>
          </div>
          <div className="right">
            <img
              src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
              alt="Farm"
            />
          </div>
        </TopBanner>
        {/* <Title1>
          <Zoom triggerOnce={true}>WHO WE ARE</Zoom>
        </Title1> */}

        <Section2 className="wrapper">
          <CarouselWrapper>
            <CarouselInner style={{ transform: `translateX(${translateX}%)` }}>
              {images.map((image, index) => (
                <CarouselItem key={index}>
                  <Image
                    src={image}
                    alt={`Slide ${index + 1}`}
                    loading="lazy"
                  />
                </CarouselItem>
              ))}
            </CarouselInner>
            <Icon left onClick={handlePrevious}>
              <PiCaretLeftBold color="brown" size={32} />
            </Icon>
            <Icon right onClick={handleNext}>
              <PiCaretRightBold color="brown" size={32} />
            </Icon>
          </CarouselWrapper>
          <Right>
            <Zoom triggerOnce={true}>
              <Title3>
                Appropriate Rural Development Agriculture Program (ARDAP) is a
                local non-governmental organization (NGO) registered in the year
                2000 based and working in Western Kenya, Busia, Bungoma,
                Kakamega and Siaya Counties. ARDAP works with smallholder
                farmers in the key areas of rural development, sustainable
                agriculture and food security, as well as natural resource
                management. ARDAP collaborates and works with government
                agencies, local and international research agencies and churches
                to provide a more holistic approach to development in rural
                areas.
                <br />
                Currently, ARDAP is organized into 'a number of organs, the
                Board of Management, which comprises of: Fundraising and
                Resource mobilization committee; infrastructure development
                committee; Research and Development committee; Finance and
                Administration committee. The day to day management is runned by
                the Executive Director who is a secretary to the board and staff
                who discharge various duties.
                <br />
                In the current strategic plan 2016-2022, ARDAP runs the
                following strategic programs; Training and capacity, Ecology
                culture and productivity, Information advocacy and networking,
                markets and value chains and resource mobilization.
              </Title3>
            </Zoom>
            <Link to="/past-projects">
              <HistoryBtn>
                View Past Projects
                <PiCaretRightBold
                  color="#fff"
                  style={{ marginLeft: "10px", marginTop: "6px" }}
                  className="icon"
                />
              </HistoryBtn>
            </Link>
          </Right>
        </Section2>
        <Section3 className="wrapper">
          <Title5>Our main objectives are: </Title5>
          <Zoom cascade damping={0.3} triggerOnce={true}>
            {objectives.map((item) => (
              <HorizontalWrapper key={item.id}>
                <FaLongArrowAltRight
                  style={{ marginRight: "20px" }}
                  color="brown"
                />
                <Title6>{item.title}</Title6>
              </HorizontalWrapper>
            ))}
          </Zoom>
        </Section3>
        <Section4>
          <Cards className="wrapper">
            <VisionCard>
              <div>
                <AiFillEye size={60} color="#fff" />
              </div>
              <h2>Vision Statement</h2>
              <h3>
                To be a Centre of excellence promoting sustainable livelihoods,
                eco-health and agri-business through improved food security and
                integrated social change.
              </h3>
            </VisionCard>
            <VisionCard>
              <div>
                <BsArrowUpRightCircleFill size={60} color="#fff" />
              </div>
              <h2>Mission Statement</h2>
              <h3>
                To improve the livelihood of the local community through
                promotion of eco-friendly farming systems, agricultural research
                and provision of extension services with a long term aim of
                ensuring sustainable food security, income generation and
                improved health standards.
              </h3>
            </VisionCard>
          </Cards>
        </Section4>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;
  & .left {
    background: #004600;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 60px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;
const Cards = styled.div`
  display: flex;

  width: 100%;
  position: relative;
  margin-bottom: 110px;
  height: fit-content;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const VisionCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0 40px;
  background: #fff;
  box-shadow: 0 5px 5px #d9d9d9;
  width: 50%;
  position: absolute;
  top: 80px;
  height: fit-content;
  border-radius: 2px;
  align-items: center;
  position: relative;
  color: #000;
  cursor: pointer;
  transition: all 0.8s linear;
  @media screen and (max-width: 700px) {
    width: 90%;
    margin: auto;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 7px;
    background-color: #004600;
    transform: scaleX(0);
    transition: all 1s linear;
  }
  &:hover {
    top: 100px;
    &::after {
      transform: scaleX(1);
    }
  }
  &:first-child {
    @media screen and (max-width: 700px) {
      margin-bottom: 80px;
    }
    @media screen and (min-width: 700px) {
      margin-right: 20px;
    }
  }
  h2 {
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 17px;
  }
  h3 {
    font-size: 19px;
    font-weight: 400;
    line-height: 37px;
    display: flex;
    text-align: center;
    padding: 0 40px;
  }
  div {
    position: absolute;
    width: 112px;
    height: 112px;
    border-radius: 50%;
    background-color: brown;
    display: grid;
    place-items: center;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 30;
  }
`;
const Section4 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;

  background-color: #d9d9d9;
`;

const Right = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #d9d9d9;
  background-color: #fff;
  width: 50%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

const HistoryBtn = styled.button`
  padding: 20px 50px;
  background-color: #004600;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 7px;
  cursor: pointer;
  width: fit-content;
  transition: all 0.7s linear;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
  &:hover {
    background-color: brown;
  }
`;
const Section2 = styled.div`
  display: flex;
  padding: 40px 0;
  margin-top: 50px;
  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
  }
`;
const CarouselWrapper = styled.div`
  position: relative;
  width: 50%;
  overflow: hidden;
  margin-right: 18px;
  height: 100%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

const Title3 = styled.h1`
  color: #000;
  font-weight: 400;
  font-size: 19px;
  margin-top: 10px;
  line-height: 30px;

  span {
    color: #0c55e9;
  }
`;
const HorizontalWrapper = styled.div`
  display: flex;

  width: 100%;
  align-items: center;
  margin-bottom: 15px;
`;
const Section3 = styled.div`
  display: flex;

  width: 100%;
  padding: 30px;
  flex-direction: column;
  box-shadow: 0 5px 5px #d9d9d9;
`;
const Title5 = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
`;
const Title6 = styled.h1`
  color: #000;
  font-weight: 400;
  font-size: 19px;

  line-height: 30px;
`;
const Image = styled.img`
  width: 100%;
  height: 500px;
  object-fit: contain;
`;
const CarouselInner = styled.div`
  display: flex;
  transition: transform 1s ease-in-out;
`;

const CarouselItem = styled.div`
  flex: 0 0 100%;
`;
const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 25px;
  /* background-color: #0c55e9; */

  cursor: pointer;
  left: ${({ left }) => left && "10px"};
  right: ${({ right }) => right && "10px"};
`;

export default WhoWeAre;
