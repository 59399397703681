import {
  ADD_TO_REPORTS_FAIL,
  ADD_TO_REPORTS_REQUEST,
  ADD_TO_REPORTS_SUCCESS,
  DELETE_REPORT_FAIL,
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_SUCCESS,
  GET_REPORTS_FAIL,
  GET_REPORTS_REQUEST,
  GET_REPORTS_SUCCESS,
  UPDATE_REPORT_FAIL,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
} from "../constants/reportConstants";

export const addToReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_REPORTS_REQUEST:
      return { ...state, loading: true };
    case ADD_TO_REPORTS_SUCCESS:
      return { ...state, loading: false, added: action.payload };
    case ADD_TO_REPORTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORTS_REQUEST:
      return { ...state, loading: true };
    case GET_REPORTS_SUCCESS:
      return { ...state, loading: false, reports: action.payload };
    case GET_REPORTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const deleteReportReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_REPORT_REQUEST:
      return { ...state, loading: true };
    case DELETE_REPORT_SUCCESS:
      return { ...state, loading: false, deleted: action.payload };
    case DELETE_REPORT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateReportReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_REPORT_REQUEST:
      return { ...state, loading: true };
    case UPDATE_REPORT_SUCCESS:
      return { ...state, loading: false, updated: action.payload };
    case UPDATE_REPORT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
