import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const GalleryItem = ({ images, caption, _id }) => {
  return (
    <Link to={`/gallery/${_id}`}>
      <Wrapper>
        <ImageContainer>
          <Image src={images[0]?.url} />
        </ImageContainer>
        <Caption>{caption}</Caption>
      </Wrapper>
    </Link>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid brown;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 13px;
  margin-bottom: 13px;
  transition: all 0.7s linear;
  width: 280px;
  &:hover {
    opacity: 0.8;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Caption = styled.h1`
  color: #000;
  font-size: 17px;
  padding: 0 14px;
`;

export default GalleryItem;
