import React from "react";

import styled from "styled-components";

const FocusArea = ({ icon, description, title }) => {
  return (
    <Wrapper>
      <Icon>{icon}</Icon>
      <VerticalWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </VerticalWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Title = styled.h3`
  color: brown;
  font-size: 16px;

  margin-bottom: 8px;
  @media screen and (max-width: 700px) {
    font-size: 13px;
  }
`;
const Description = styled.h3`
  color: #fff;
  font-size: 15px;

  margin-bottom: 8px;

  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
`;

const Icon = styled.div`
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-right: 10px;
  background-color: white;
`;
export default FocusArea;
