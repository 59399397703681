import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  changeActiveTab,
  openSidebar,
  changeActiveAbout,
  changeActiveDonor,
  changeActivePublication,
} from "../redux/actions/activeTabActions";

import { pageLinks } from "../data/pageLinks";
import { Zoom } from "react-awesome-reveal";

const Sidebar = () => {
  const activeIndex = useSelector((state) => state.activeTab);
  const isSidebarOpen = useSelector((state) => state.sidebarOpen);
  const dispatch = useDispatch();
  const [subLinks, setSubLinks] = useState(null);

  const handleClick = (index, pageLink) => {
    if (
      pageLink.links?.length > 0 ||
      pageLink.publications?.length > 0 ||
      pageLink.donors?.length > 0
    ) {
      if (subLinks === index) {
        setSubLinks(null);
      } else {
        setSubLinks(index);
      }

      return;
    } else {
      dispatch(changeActiveTab(index));
      dispatch(changeActiveAbout(null));
      dispatch(changeActiveDonor(null));
      dispatch(changeActivePublication(null));
      dispatch(openSidebar(false));
    }
  };
  const handleAboutClick = (index) => {
    dispatch(changeActiveTab(1));

    dispatch(changeActiveAbout(index));
    dispatch(openSidebar(false));
  };
  const handlePublicationClick = (index) => {
    dispatch(changeActiveTab(2));
    dispatch(changeActivePublication(index));
    dispatch(changeActiveAbout(null));
    dispatch(openSidebar(false));
  };
  const handleDonorClick = (index) => {
    dispatch(changeActiveTab(2));
    dispatch(changeActivePublication(null));
    dispatch(changeActiveAbout(null));
    dispatch(changeActiveDonor(index));
    dispatch(openSidebar(false));
  };

  return (
    <Wrapper open={isSidebarOpen?.open}>
      <SidebarItems>
        <Zoom cascade damping={0.1} triggerOnce={true}>
          {pageLinks.map((pageLink, index) => (
            <Item>
              <Link to={pageLink.url}>
                <PageLink
                  key={pageLink.id}
                  active={
                    index === (activeIndex.index || 0) || index === subLinks
                  }
                  onClick={() => handleClick(index, pageLink)}
                >
                  {pageLink.name}
                  {pageLink.icon && pageLink.icon}
                </PageLink>
              </Link>
              {pageLink.links?.length > 0 && (
                <Details active={subLinks === index}>
                  {pageLink.links.map((link, index) => (
                    <Link to={link.url}>
                      <Detail
                        key={link.id}
                        onClick={() => handleAboutClick(index)}
                        active={index === activeIndex.about}
                      >
                        {link.title}
                      </Detail>
                    </Link>
                  ))}
                </Details>
              )}
              {pageLink.publications?.length > 0 && (
                <Details active={subLinks === index}>
                  {pageLink.publications.map((link, index) => (
                    <Link to={`/publications${link.url}`}>
                      <Detail
                        key={link.id}
                        onClick={() => handlePublicationClick(index)}
                        active={index === activeIndex.publication}
                      >
                        {link.title}
                      </Detail>
                    </Link>
                  ))}
                </Details>
              )}
              {pageLink.donors?.length > 0 && (
                <Details active={subLinks === index}>
                  {pageLink.donors.map((link, index) => (
                    <Link to={`/donors-and-partners${link.url}`}>
                      <Detail
                        key={link.id}
                        onClick={() => handleDonorClick(index)}
                        active={index === activeIndex.donor}
                      >
                        {link.title}
                      </Detail>
                    </Link>
                  ))}
                </Details>
              )}
            </Item>
          ))}
        </Zoom>
      </SidebarItems>
      {/* <CloseIcon onClick={() => dispatch(openSidebar(false))}>
        <AiOutlineClose color="red" />
      </CloseIcon> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
  padding: 30px 45px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media screen and (min-width: 800px) {
    display: none;
  }
  @media screen and (max-width: 800px) {
    position: fixed;
    margin: 0;
    z-index: 1000;
    background-color: #fff;
    display: block;
    transform: ${(props) =>
      props.open ? "translateX(0)" : "translateX(-120%)"};
    /* &::after {
      content: "";
      position: fixed;
      width: calc(100vw - 310px);
      margin-left: 310px;
      height: 100vh;
      z-index: -1;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;
      display: ${(props) => (props.open ? "block" : "none")};
    } */
  }
  transition: all 0.8s ease-in;
`;
const SidebarItems = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  z-index: 100;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

// const CloseIcon = styled.div`
//   position: absolute;
//   top: 20px;
//   right: 20px;
//   padding: 10px;
//   border-radius: 50%;
//   background-color: #c4c4c4;
//   cursor: pointer;
//   display: none;
//   @media screen and (max-width: 800px) {
//     display: block;
//     z-index: 10000;
//   }
// `;

const Details = styled.div`
  background-color: #fff;

  display: flex;

  flex-direction: column;

  z-index: 20;
  padding-left: 12px;
  transition: all 1.3s linear;
  border-bottom: 1px solid #d9d9d9;
  min-width: 150px;
  width: 100%;
  display: ${({ active }) => (active === true ? "block" : "none")};
  opacity: ${({ active }) => (active === true ? "1" : "0")};
  flex-direction: column;
  transition: all 0.5s linear;
`;
const PageLink = styled.div`
  font-family: "Anton,Sans serif";
  font-style: bold;
  font-weight: 600;
  height: fit-content;
  width: 100%;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => (props.active ? "brown" : "#000")};
  margin-right: 40px;
  transition: all 0.9s linear;
  position: relative;
  padding: 20px 0;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  &:hover {
    color: brown;
  }
`;
const Detail = styled.h1`
  font-size: 15px;

  color: #000;
  font-weight: 400;
  transition: all 0.8s linear;
  position: relative;
  padding: 5px 0;
  white-space: nowrap;
  color: ${(props) => (props.active ? "brown" : "#000")};
  &:hover {
    opacity: 0.8;
    color: brown;
  }
`;
export default Sidebar;
