import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import { getHomeImages } from "../redux/actions/homeImagesActions";
import { useDispatch, useSelector } from "react-redux";
import { useIsMount } from "../hooks/useIsMount";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import FocusArea from "../components/FocusArea";

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const focusAreas = [
    {
      id: 0,
      title: "Sustainable Agriculture",
      icon: "🌾",
      description:
        "Promoting farming practices that ensure long-term productivity while minimizing environmental impact. Focus includes soil health, water conservation, and biodiversity conservation.",
    },
    {
      id: 1,
      title: "Natural Resource Management",
      icon: "🌳",
      description:
        "Efficient and responsible use of natural resources such as land, water, and forests. Emphasis on sustainable practices to maintain ecological balance and support rural development.",
    },
    {
      id: 2,
      title: "Livelihood Programs",
      icon: "💼",
      description:
        "Initiatives designed to improve the economic well-being of local communities. This may include income-generating activities, vocational training, and support for entrepreneurship.",
    },
    {
      id: 3,
      title: "Social Behavior Change",
      icon: "🔄",
      description:
        "Addressing and influencing societal norms and behaviors for positive outcomes. Programs may focus on education, awareness, and community engagement to bring about positive social change.",
    },
    {
      id: 4,
      title: "Inclusivity",
      icon: "🤝",
      description:
        "Ensuring that development initiatives include and benefit all members of the community, irrespective of gender, age, or socio-economic status. Striving for equal opportunities and representation.",
    },
    {
      id: 5,
      title: "Community Health and Nutrition",
      icon: "🍏",
      description:
        "Promoting overall health and well-being within the community. This includes access to healthcare services, nutritional education, and initiatives to improve the health standards of vulnerable households.",
    },
  ];

  useEffect(() => {
    document.title =
      "ARDAP-Appropriate Rural Development Agriculture Program (ARDAP)";
  }, []);
  const { images, loading, error } = useSelector(
    (state) => state.getHomeImages
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHomeImages());
    // eslint-disable-next-line
  }, []);
  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      if (error) {
        errorExists(error);
      }
    }
    // eslint-disable-next-line
  }, [error]);
  let intervalRef = useRef(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images?.length);
    }, 7000);

    return () => clearInterval(intervalRef.current);
  }, [images]);

  useEffect(() => {
    setTranslateX(-currentIndex * 100);
  }, [currentIndex]);

  const handleNext = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === images?.length ? 0 : prevIndex + 1
    );
  };
  const handlePrevious = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? images?.length - 1 : prevIndex - 1
    );
  };
  const { userInfo } = useSelector((state) => state.signIn);

  return (
    <Wrapper>
      <head>
        <meta
          name="keywords"
          content="Appropriate Rural Development Agriculture Program (ARDAP)"
        />
      </head>
      <Content>
        <Section2 className="wrapper">
          <Title1 className="wrapper">
            <span>ARDAP </span> - Appropriate Rural Development Agriculture
            Program
          </Title1>
          {userInfo?.user && (
            <Link to="/admin/admin-dashboard">
              <Button> View Admin dashboard</Button>
            </Link>
          )}
          {loading ? (
            <Loader />
          ) : images?.length === 0 ? (
            <h1 style={{ marginTop: "40px" }}>There are no images to show!</h1>
          ) : (
            <CarouselWrapper>
              <CarouselInner
                style={{ transform: `translateX(${translateX}%)` }}
              >
                {images?.map((image, index) => (
                  <>
                    <CarouselItem key={index}>
                      <Caption>{image.caption}</Caption>
                      <Image
                        src={image.image?.url}
                        alt={`Slide ${index + 1}`}
                        loading="lazy"
                      />
                    </CarouselItem>
                  </>
                ))}
              </CarouselInner>
              <Icon left onClick={handlePrevious}>
                <PiCaretLeftBold
                  color="brown"
                  size={38}
                  style={{ fontWeight: "bolder" }}
                />
              </Icon>
              <Icon right onClick={handleNext}>
                <PiCaretRightBold color="brown" size={38} />
              </Icon>
            </CarouselWrapper>
          )}
        </Section2>
        <Section3>
          <h1>Areas of FOCUS</h1>
          <h2 className="wrapper">
            ARDAP collaborates and works with government agencies, local and
            international research agencies and churches to provide a more
            holistic approach to development in rural areas.{" "}
          </h2>
          <FocusList className="wrapper">
            {focusAreas.map((area) => (
              <FocusArea key={area.id} {...area} />
            ))}
          </FocusList>
        </Section3>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
  /* height: 100vh; */
  min-height: calc(100vh - 150px);
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  height: 100%;
`;
const Button = styled.button`
  padding: 0 20px;
  height: 50px;

  background: #004600;
  margin: 12px 0;
  border: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.5s linear;
  &:hover {
    border-radius: 26px;
    color: #e5e5e5;
  }
`;

const Section2 = styled.div`
  display: flex;
  padding: 40px 0;
  height: 100% !important;
  flex-direction: column;
`;
const Section3 = styled.div`
  display: flex;
  padding: 40px 0;
  background-color: #000;
  flex-direction: column;
  align-items: center;
  h1 {
    color: brown;
    margin-bottom: 20px;
    margin-top: 20px;
    @media screen and (max-width: 700px) {
      font-size: 22px;
    }
  }
  h2 {
    color: #fff;
    max-width: 1000px;
    margin-bottom: 20px;
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
`;
const FocusList = styled.div`
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-right: 18px;
  height: 100%;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

const Caption = styled.h1`
  color: white;
  font-weight: 900;
  font-size: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 900px;
  font-family: sans-serif;

  z-index: 5;
  transform: translate(-50%, -50%);
  line-height: 30px;
  overflow: hidden;
  @media screen and (max-width: 700px) {
    font-size: 22px;
    width: 70%;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 550px;
`;
const CarouselInner = styled.div`
  display: flex;
  transition: transform 1.4s ease-in-out;
  position: relative;
  height: 100%;
`;

const CarouselItem = styled.div`
  flex: 0 0 100%;
  position: relative;
  height: 100%;
`;
const Icon = styled.div`
  border: 1px solid brown;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  /* background-color: #0c55e9; */
  @media screen and (max-width: 700px) {
    padding: 10px;
  }
  cursor: pointer;
  left: ${({ left }) => left && "10px"};
  right: ${({ right }) => right && "10px"};
`;
const Title1 = styled.h1`
  color: brown;
  font-weight: 900;
  font-size: 30px;
  margin: 20px 0;
  display: flex;
  align-self: center;
  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
  span {
    color: #004600;
  }
`;
export default Home;
