import React, { useEffect } from "react";

import styled from "styled-components";

import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminNewsAndEventsDetails = () => {
  const location = useLocation();
  const itemId = location.pathname.split("/")[3];

  const { newsAndEvents } = useSelector((state) => state.getAllNews);

  const currentItem = newsAndEvents?.events?.find(
    (item) => item._id === itemId.toString()
  );

  const inputDate = new Date(currentItem?.event_date);

  const options = { year: "numeric", month: "short", day: "2-digit" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    inputDate
  );
  useEffect(() => {
    document.title = currentItem?.title;
  }, [currentItem?.title]);
  return (
    <Wrapper>
      <TopBanner>
        <div className="left">
          <h1>News or events details</h1>
        </div>
        <div className="right">
          <img
            src="https://media.istockphoto.com/id/1401722160/photo/sunny-plantation-with-growing-soya.webp?b=1&s=170667a&w=0&k=20&c=evvmxmpiRM6sbnk59z876swwplz8mQN_ZxQWIO2eKA8="
            alt="Farm"
          />
        </div>
      </TopBanner>
      <Link to="/admin/admin-news-and-events">
        <Button back>Back</Button>
      </Link>
      <ItemWrapper>
        <Image src={currentItem?.image?.url} />
        <Caption>{currentItem?.title}</Caption>
        <Author>{`By: ${currentItem?.author} On: ${new Date(
          currentItem?.createdAt
        ).getDate()}-${
          new Date(currentItem?.createdAt).getMonth() + 1
        }-${new Date(currentItem?.createdAt).getFullYear()}`}</Author>
        {currentItem?.event_date && (
          <EventDate>{`Date: ${formattedDate}`}</EventDate>
        )}
        <Description>{currentItem?.description}</Description>
      </ItemWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  padding: 30px 0;
`;

const TopBanner = styled.div`
  display: flex;
  width: 100%;
  height: 170px;
  & .left {
    background: #004600;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 60px;
    h1 {
      color: white;
      @media screen and (max-width: 700px) {
        font-size: 17px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
    }
  }
  & .right {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
      overflow: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #004600, rgba(0, 70, 0, 0.5));
      filter: blur(6px);
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background-color: rgba(0, 70, 0, 0.5);
      filter: blur(6px);
      z-index: 10;
    }
  }
`;
const ItemWrapper = styled.div`
  display: flex;

  width: 90vw;
  max-width: 700px;
  flex-direction: column;

  padding-bottom: 14px;
  &:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  transition: all 0.7s linear;
  &:hover {
    opacity: 0.8;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const Caption = styled.h1`
  color: #000;
  font-size: 17px;
  padding: 0 14px;
  position: relative;
`;
const Description = styled.h1`
  color: #000;
  font-size: 15px;
  padding: 0 14px;
  font-style: italic;
  margin: 10px 0;
`;
const Author = styled.h1`
  color: #000;
  font-size: 15px;
  padding: 7px 14px;
`;
const EventDate = styled.h1`
  color: brown;
  font-size: 15px;
  padding: 7px 14px;
`;
const Button = styled.button`
  background: #004600;
  border-radius: 8px;
  border: none;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  /* width: 400px; */
  padding: 10px 40px;
  margin-top: 30px;
  margin-bottom: ${(props) => props.back && "20px"};
  cursor: pointer;
  transition: all 0.6s linear;
  &:disabled {
    cursor: not-allowed;
    background: gray;
  }
  &:hover {
    opacity: 0.8;
  }
`;
export default AdminNewsAndEventsDetails;
