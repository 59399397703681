import {
  ADD_TO_GALLERY_FAIL,
  ADD_TO_GALLERY_REQUEST,
  ADD_TO_GALLERY_SUCCESS,
  DELETE_GALLERY_IMAGES_FAIL,
  DELETE_GALLERY_IMAGES_REQUEST,
  DELETE_GALLERY_IMAGES_SUCCESS,
  GET_GALLERY_FAIL,
  GET_GALLERY_REQUEST,
  GET_GALLERY_SUCCESS,
  UPDATE_GALLERY_IMAGES_FAIL,
  UPDATE_GALLERY_IMAGES_REQUEST,
  UPDATE_GALLERY_IMAGES_SUCCESS,
} from "../constants/galleryConstants";

export const addToGalleryReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_GALLERY_REQUEST:
      return { ...state, loading: true };
    case ADD_TO_GALLERY_SUCCESS:
      return { ...state, loading: false, added: action.payload };
    case ADD_TO_GALLERY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getGalleryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_GALLERY_REQUEST:
      return { ...state, loading: true };
    case GET_GALLERY_SUCCESS:
      return { ...state, loading: false, images: action.payload };
    case GET_GALLERY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const deleteGalleryImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_GALLERY_IMAGES_REQUEST:
      return { ...state, loading: true };
    case DELETE_GALLERY_IMAGES_SUCCESS:
      return { ...state, loading: false, image: action.payload };
    case DELETE_GALLERY_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateGalleryImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_GALLERY_IMAGES_REQUEST:
      return { ...state, loading: true };
    case UPDATE_GALLERY_IMAGES_SUCCESS:
      return { ...state, loading: false, updated: action.payload };
    case UPDATE_GALLERY_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
