import styled from "styled-components";

import { Zoom } from "react-awesome-reveal";
import { socials } from "../data/socials";
const Footer = () => {
  return (
    <Wrapper>
      <BottomWrapper>
        <Bottom className="wrapper">
          <Left>
            <Zoom triggerOnce={true}>
              <Title>
                &copy; {new Date().getFullYear()} ARDAP | All Rights Reserved.
              </Title>
            </Zoom>
          </Left>
          <Title>
            Website developed by{" "}
            <Netgenix href="https://netgenixicons.com" target="_blank">
              Netgenix Icons
            </Netgenix>
          </Title>
          <Right>
            {socials.map((social, i) => (
              <Social href={social.url} target="_blank" key={i}>
                <Zoom triggerOnce={true}>{social.icon}</Zoom>
              </Social>
            ))}
          </Right>
        </Bottom>
      </BottomWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const BottomWrapper = styled.div`
  width: 100vw;

  height: fit-content;
  background: #004600;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  display: flex;
`;
const Right = styled.div`
  display: flex;
`;
const Social = styled.a`
  &:not(:last-child) {
    margin-right: 12px;
  }
  color: #fff;
  transition: all 0.5s linear;
  &:hover {
    opacity: 0.8;
    color: #ff0000;
  }
`;
const Title = styled.h1`
  color: #fff;
  font-size: 16px;
  @media screen and (max-width: 700px) {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;
const Netgenix = styled.a`
  color: brown;
  font-size: 16px;
  @media screen and (max-width: 700px) {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;
export default Footer;
