import axios from "../http-file";
import {
  CREATE_NEWS_AND_EVENT_FAIL,
  CREATE_NEWS_AND_EVENT_REQUEST,
  CREATE_NEWS_AND_EVENT_SUCCESS,
  DELETE_NEWS_FAIL,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  GET_ALL_NEWS_AND_EVENTS_FAIL,
  GET_ALL_NEWS_AND_EVENTS_REQUEST,
  GET_ALL_NEWS_AND_EVENTS_SUCCESS,
  GET_NEWS_AND_EVENTS_FAIL,
  GET_NEWS_AND_EVENTS_REQUEST,
  GET_NEWS_AND_EVENTS_SUCCESS,
  UPDATE_NEWS_FAIL,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
} from "../constants/newsConstants";
export const getNewsAndEvents = (page) => async (dispatch, getState) => {
  dispatch({ type: GET_NEWS_AND_EVENTS_REQUEST });
  try {
    const { data } = await axios.get(`/news-and-events?page=${page}`);
    dispatch({ type: GET_NEWS_AND_EVENTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_NEWS_AND_EVENTS_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
export const getAllNewsAndEvents = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALL_NEWS_AND_EVENTS_REQUEST });
  try {
    const { data } = await axios.get(`/news-and-events/admin`);
    dispatch({ type: GET_ALL_NEWS_AND_EVENTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_NEWS_AND_EVENTS_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const createNewsAndEvents =
  (newsAndEvent) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_NEWS_AND_EVENT_FAIL,
      payload: null,
    });
    dispatch({
      type: CREATE_NEWS_AND_EVENT_SUCCESS,
      payload: null,
    });
    dispatch({ type: CREATE_NEWS_AND_EVENT_REQUEST });
    try {
      const token = getState()?.signIn?.userInfo?.token;

      const { data } = await axios.post("/news-and-events", newsAndEvent, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: CREATE_NEWS_AND_EVENT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_NEWS_AND_EVENT_FAIL,
        payload:
          error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message,
      });
    }
  };

export const deleteNews = (itemId) => async (dispatch, getState) => {
  dispatch({ type: DELETE_NEWS_FAIL, payload: null });
  dispatch({ type: DELETE_NEWS_SUCCESS, payload: null });
  dispatch({ type: DELETE_NEWS_REQUEST });

  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.delete(`/news-and-events/${itemId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: DELETE_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_NEWS_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
export const updateNews = (itemId, update) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_NEWS_FAIL, payload: null });
  dispatch({ type: UPDATE_NEWS_SUCCESS, payload: null });
  dispatch({ type: UPDATE_NEWS_REQUEST });

  try {
    const token = getState()?.signIn?.userInfo?.token;
    const { data } = await axios.put(`/news-and-events/${itemId}`, update, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: UPDATE_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_NEWS_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
