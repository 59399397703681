import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HomePageItem = ({ image, caption, _id, setShowDelete, setItemId }) => {
  const handleDelete = () => {
    setItemId(_id);
    setShowDelete(true);
  };
  return (
    <Wrapper>
      <Image src={image.url} />
      <Caption>{caption}</Caption>
      <Buttons>
        <Link to={`/admin/admin-update-home-images/${_id}`}>
          <UpdateButton>Update</UpdateButton>
        </Link>
        <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
      </Buttons>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: 270px;
  max-width: 470px;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid brown;

  border-radius: 10px;
  padding-bottom: 14px;
  &:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  transition: all 0.7s linear;
  &:hover {
    opacity: 0.8;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const Caption = styled.h1`
  color: #000;
  font-size: 17px;
  padding: 0 14px;
  position: relative;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  margin-top: 5px;
`;
const DeleteButton = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  font-family: "Dm Sans";
  background: red;
  border-radius: 10px;
  padding: 7px 20px;
  color: #ffffff;
  cursor: pointer;
`;
const UpdateButton = styled(DeleteButton)`
  background: #004600;
`;
export default HomePageItem;
