import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Zoom } from "react-awesome-reveal";
import {
  changeActiveAdminTab,
  openAdminSidebar,
} from "../../redux/actions/activeTabActions";
import { adminPageLinks } from "../../data/pageLinks";

const AdminSidebar = () => {
  const activeIndex = useSelector((state) => state.activeTab);
  const isSidebarOpen = useSelector((state) => state.sidebarOpen);
  const dispatch = useDispatch();

  const handleClick = (index) => {
    dispatch(changeActiveAdminTab(index));

    dispatch(openAdminSidebar(false));
  };

  return (
    <Wrapper open={isSidebarOpen?.adminOpen}>
      <SidebarItems>
        <Zoom cascade damping={0.1} triggerOnce={true}>
          {adminPageLinks.map((pageLink, index) => (
            <Item>
              <Link to={pageLink.url}>
                <PageLink
                  key={pageLink.id}
                  active={index === (activeIndex.adminIndex || 0)}
                  onClick={() => handleClick(index)}
                >
                  {pageLink.name}
                </PageLink>
              </Link>
            </Item>
          ))}
        </Zoom>
      </SidebarItems>
      {/* <CloseIcon onClick={() => dispatch(openAdminSidebar(false))}>
        <AiOutlineClose color="red" />
      </CloseIcon> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
  padding: 30px 45px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media screen and (min-width: 800px) {
    display: none;
  }
  @media screen and (max-width: 800px) {
    position: fixed;
    margin: 0;
    z-index: 1000;
    background-color: #fff;
    display: block;
    transform: ${(props) =>
      props.open ? "translateX(0)" : "translateX(-120%)"};
  }
  transition: all 0.8s ease-in;
`;
const SidebarItems = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  z-index: 100;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

// const CloseIcon = styled.div`
//   position: absolute;
//   top: 20px;
//   right: 20px;
//   padding: 10px;
//   border-radius: 50%;
//   background-color: #c4c4c4;
//   cursor: pointer;
//   display: none;
//   @media screen and (max-width: 800px) {
//     display: block;
//     z-index: 10000;
//   }
// `;

const PageLink = styled.div`
  font-family: "Anton,Sans serif";
  font-style: bold;
  font-weight: 600;
  height: fit-content;
  width: 100%;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => (props.active ? "brown" : "#000")};
  margin-right: 40px;
  transition: all 0.9s linear;
  position: relative;
  padding: 20px 0;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  &:hover {
    color: brown;
  }
`;

export default AdminSidebar;
